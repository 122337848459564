import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
  Slide,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/vi';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import {
  FacebookShareButton,
  FacebookIcon,
  ViberShareButton,
  ViberIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from 'react-share';
import { useHistory } from 'react-router-dom';

import CustomButton from 'components/CustomButtons/Button';
import Copyable from 'components/Copyable';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Sidebar from './Sidebar';
import useStyles from './index.style';
import { IMAGES, VIDEO_TYPES, VIDEO_STATUS, ROUTES } from '../../constants';
import apis from '../../apis';
import { FACEBOOK_APP_ID } from '../../configs';
import { getFileName } from '../../utils/file';
import actions from '../../redux/actions';

const PAGE_SIZE = 12;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PersonalPage = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('userPage');

  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const { userInfo } = useSelector((state) => state.auth);
  const { id: accountId } = userInfo;

  // Videos
  const [videoType, setVideoType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Modal
  const [playVideo, setPlayVideo] = useState(null);
  const [alert, setAlert] = useState(null);
  const [shareUrl, setShareUrl] = useState();

  // useEffect(() => {
  //   if (total > totalVideoCurrentUse) {
  //     dispatch(actions.auth.updateTotalVideo(total));
  //   }
  // }, [totalVideoCurrentUse, total]);

  const hideAlert = () => {
    setAlert(null);
  };

  const fetchListVideos = async (params, showLoading = true) => {
    if (showLoading) setLoading(true);
    const { page = 1, limit } = params;
    const res = await apis.video.getListVideos({
      ...params,
      limit: limit || PAGE_SIZE,
    });
    const { error, list, total } = res;
    if (error === 0) {
      setVideos((prevState) => {
        const newData = page !== 1 ? [...prevState, ...list] : [...list];
        return newData;
      });
      setTotal(total);
      setCurrentPage(page);
    }
    if (showLoading) setLoading(false);
  };

  const listProcessing = useMemo(() => {
    const processingData = videos.filter(
      ({ status }) => status === VIDEO_STATUS.PROCESS,
    );
    return processingData.map(({ id }) => id);
  }, [videos]);

  useEffect(() => {
     const interval = setInterval(() => {
       if (listProcessing.length) {
         const params = { page: 1, account_id: accountId };
         if (videoType !== null) {
           params.type = videoType;
         }
         if (videos.length > PAGE_SIZE) {
           params.limit = videos.length;
         }
         fetchListVideos(params, false);
       } else {
         clearInterval(interval);
       }
     }, 5000);

     return () => clearInterval(interval);
  }, [listProcessing, accountId, videoType]);

  const hasMore = useMemo(() => {
    return videos.length < total;
  }, [videos, total]);

  useEffect(() => {
    const params = { page: 1, account_id: accountId };
    if (videoType !== null) {
      params.type = videoType;
    }
    fetchListVideos(params);
    if (!matches) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [videoType]);

  const timeAgo = (value) => {
    if (!value) return '';
    moment.locale(i18n.language);
    return moment(value).fromNow();
  };

  const handleGotoPricing = () => {
    history.push(ROUTES.PRICING);
  };

  const showCreateTemplateError = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => handleGotoPricing()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        {t('cannotCreateTemplate')}
      </SweetAlert>,
    );
    return;
  };

  const handlePlayVideo = (video) => {
    const { status } = video;
    if (status !== VIDEO_STATUS.SUCCESS) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
        >
          {t('cannotPlayVideo')}
        </SweetAlert>,
      );
      return;
    }
    setPlayVideo(video);
  };

  const upgradeSHD = (video) => {
    const { status, title } = video;

    if ( title && title.indexOf("[SHD]")>=0 ) return false;

    async function doUpgradeHd() {
      console.log({
        id: video.id,
        video: video.publicLink,
        mc: video.image.split("/").pop()
      })
      const res = await apis.video.upgradeSHD({
        id: video.id,
        video: video.publicLink,
        mc: video.image.split("/").pop()
      });
      console.log(res)
      if ( res.error ) {
        setAlert(
          <SweetAlert
            danger
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('notification')}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
          >
            {res.errorMsg}
          </SweetAlert>,
        );
      }
      fetchListVideos({ page: 1, account_id: accountId });
    }
    
    setAlert(
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => { doUpgradeHd(); hideAlert() }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnText={t('cancel')}
      >
        Bạn có muốn tăng chất lượng video lên không? (Hành động này sẽ tốn 1 video render)
      </SweetAlert>,
    );
  };

  const upgradeHD = (video) => {
    const { status, title } = video;

    if ( title && title.indexOf("[HD]")>=0 ) return false;

    async function doUpgradeHd() {
      console.log(video)
      const res = await apis.video.upgradeVideo({
        id: video.id,
        video: video.publicLink
      });
      // const { error, errorMsg } = res;
      fetchListVideos({ page: 1, account_id: accountId });
    }
    
    setAlert(
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => { doUpgradeHd(); hideAlert() }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnText={t('cancel')}
      >
        Bạn có muốn tăng chất lượng video lên không? (Hành động này sẽ tốn 1 video render)
      </SweetAlert>,
    );
  };

  const faceSwap = async (event, video) => {
    const formData = new FormData();
    formData.append('image', event.target.files[0]);
    const res = await apis.resource.uploadFile({
      formData,
    });

    const { status, title } = video;

    if ( title.indexOf("[FS]")>=0 ) return false;

    await apis.video.faceswapVideo({
      id: video.id,
      video: video.publicLink,
      image: res.public
    });
    // const { error, errorMsg } = res;
    fetchListVideos({ page: 1, account_id: accountId });
  };

  const handleDownloadVideo = (url) => {
    const fileName = getFileName(url);
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const newUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = newUrl;
      link.setAttribute('target', '_blank');
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  const handleShareVideo = (url) => {
    setShareUrl(url);
  };

  const handleDeleteVideo = async (videoId) => {
    const res = await apis.video.deleteVideo(videoId);
    const { error, errorMsg } = res;

    if (error === 0) {
      const params = { page: 1, account_id: accountId };
      if (videoType !== null) {
        params.type = videoType;
      }
      fetchListVideos(params);
      hideAlert();
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
        >
          {errorMsg}
        </SweetAlert>,
      );
    }
  };

  const handleConfirmDelete = (video) => {
    const { id, title } = video;

    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('areYouSure')}
        onConfirm={() => handleDeleteVideo(id)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('confirm')}
        cancelBtnText={t('cancel')}
        showCancel
      >
        {t('youSure')} <b>{title}</b>
      </SweetAlert>,
    );
  };

  const renderActions = (video, index) => {
    const { status, publicLink } = video;
    return (
      <>
        {status === VIDEO_STATUS.SUCCESS && (
          <>
            <IconButton
              key={`download - ${index}`}
              onClick={() => handleDownloadVideo(publicLink)}
            >
              <img src={IMAGES.iconDownload} alt="" />
            </IconButton>
            {/* <IconButton
              key={`share - ${index}`}
              onClick={() => handleShareVideo(publicLink)}
            >
              <img src={IMAGES.iconShare} alt="" />
            </IconButton> */}
          </>
        )}
        <IconButton
          key={`delete - ${index}`}
          onClick={() => handleConfirmDelete(video)}
        >
          <CancelIcon className={classes.iconDel} />
        </IconButton>
      </>
    );
  };

  const handleLoadmore = () => {
    const params = { page: currentPage + 1, account_id: accountId };
    if (videoType !== null) {
      params.type = videoType;
    }
    fetchListVideos(params);
  };

  return (
    <div className={classes.personPage}>
      <Sidebar showCreateTemplateError={showCreateTemplateError} />
      <div className={classes.mainPerson}>
        <div className={classes.header}>
          <h2 className={classes.headerTitle}>{t('lastedVideo')}</h2>
          <div className={classes.option}>
            <CustomButton
              simple
              className={classNames({
                [classes.videoType]: true,
                [classes.videoTypeSelected]: videoType === null,
              })}
              onClick={() => setVideoType(null)}
            >
              {t('all')}
            </CustomButton>
            <CustomButton
              simple
              className={classNames({
                [classes.videoType]: true,
                [classes.videoTypeSelected]: videoType === VIDEO_TYPES.SCRIPT,
              })}
              onClick={() => setVideoType(VIDEO_TYPES.SCRIPT)}
            >
              {t('videoScript')}
            </CustomButton>
            <CustomButton
              simple
              className={classNames({
                [classes.videoType]: true,
                [classes.videoTypeSelected]: videoType === VIDEO_TYPES.MC,
              })}
              onClick={() => setVideoType(VIDEO_TYPES.MC)}
            >
              {t('mcGreen')}
            </CustomButton>
          </div>
        </div>
        <div className={classes.videoListContainer}>
          {videos.length === 0 && (
            <p className={classes.videoNotFound}>{t('videoNotFound')}</p>
          )}
          {videos && (
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadmore}
              hasMore={!loading && hasMore}
            >
              <GridContainer>
                {videos.map((video, index) => {
                  const { id, title, image, status, percent, createdAt } =
                    video;
                  let statusText = '';
                  if (status === VIDEO_STATUS.SUCCESS) {
                    statusText = t('success');
                  } else if (status === VIDEO_STATUS.ERROR) {
                    statusText = t('error');
                  } else if (status === VIDEO_STATUS.PROCESS) {
                    statusText = t('processing', { percent: percent || 0 });
                  }

                  return (
                    <GridItem
                      key={`video - ${index}`}
                      xs={12}
                      sm={6}
                      md={4}
                      xl={3}
                      className={classes.videoItem}
                    >
                      <div key={id} className={classes.thumbnailContainer}>
                        <img className={classes.thumbnail} src={image} />
                        <div
                          className={classNames({
                            [classes.videoStatus]: true,
                            [classes['videoStatus' + status]]: true,
                          })}
                        >
                          {statusText}
                        </div>
                        <div className={classes.thumbnailPlay}>
                          <IconButton
                            className={classes.playIcon}
                            onClick={() => handlePlayVideo(video)}
                          >
                            <img src={IMAGES.playIcon} />
                          </IconButton>
                          <div style={{ 
                            position: 'absolute',
                            right: '10px',
                            bottom: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                            zIndex: 2,
                            gap: '4px'
                           }}>
                           {(video.title || "").indexOf('[SHD]')!=0 && (accountId==6501 || accountId==19318) && (<div style={{
                                cursor: 'pointer',
                                padding: '4px',
                                backgroundColor: 'rgba(190,190,190,0.3)',
                                borderRadius: '4px'
                            }} onClick={() => upgradeSHD(video)}>
                              <img src="/images/hd-plus.png" style={{
                                width: '20px'
                              }} />
                            </div>)}

                             {(video.title || "").indexOf('[HD]')!=0 && (accountId==6501 || accountId==19318) && (<div style={{
                                cursor: 'pointer',
                                padding: '4px',
                                backgroundColor: 'rgba(190,190,190,0.3)',
                                borderRadius: '4px'
                            }} onClick={() => upgradeHD(video)}>
                              <img src="/images/hd.png" style={{
                                width: '20px'
                              }} />
                            </div>)}
                             {(video.title || "").indexOf('[FS]')!=0 && (accountId==6501 || accountId==19318) && (<div style={{
                                cursor: 'pointer',
                                padding: '4px',
                                backgroundColor: 'rgba(190,190,190,0.3)',
                                borderRadius: '4px',
                                position: 'relative'
                            }}>
                            <input type="file" style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: '28px',
                                height: '28px',
                                opacity: 0,
                                cursor: 'pointer',
                            }} onChange={(e) => faceSwap(e, video)} accept="image/*" />
                              <img src="/images/faceswap.png" style={{
                                width: '20px'
                              }} />
                            </div>)}
                          </div>
                        </div>
                      </div>
                      <div className={classes.videoFooter}>
                        <div className={classes.videoTitleContainer}>
                          <p className={classes.videoTitle}>{title}</p>
                          <p className={classes.videoTime}>
                            {timeAgo(createdAt)}
                          </p>
                        </div>
                        <div className={classes.videoActions}>
                          {renderActions(video, index)}
                        </div>
                      </div>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </InfiniteScroll>
          )}
          {loading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(playVideo)}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPlayVideo(null)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setPlayVideo(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          {playVideo && (
            <video className={classes.playVideo} controls>
              <source src={playVideo?.publicLink} type="video/mp4" />
            </video>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(shareUrl)}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShareUrl(null)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShareUrl(null)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
          <h4
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('share')}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <div>
            <FacebookShareButton
              className={classes.socialButton}
              url={shareUrl}
            >
              <FacebookIcon />
            </FacebookShareButton>
            <ViberShareButton className={classes.socialButton} url={shareUrl}>
              <ViberIcon />
            </ViberShareButton>
            <FacebookMessengerShareButton
              className={classes.socialButton}
              appId={FACEBOOK_APP_ID}
              url={shareUrl}
            >
              <FacebookMessengerIcon />
            </FacebookMessengerShareButton>
            <EmailShareButton className={classes.socialButton} url={shareUrl}>
              <EmailIcon />
            </EmailShareButton>
          </div>
          <Copyable id="share-video" text={shareUrl || ''} />
        </DialogContent>
      </Dialog>

      {alert}
    </div>
  );
};

export default PersonalPage;
