import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './InfoHeader.style';

const InfoHeader = ({ info, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h4 className={classes.info}>{info}</h4>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.underline} />
    </div>
  );
};

export default InfoHeader;

InfoHeader.propTypes = {
  info: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
