import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// @material-ui/core components
import {
  AppBar,
  ClickAwayListener,
  Divider,
  Drawer,
  Grow,
  Hidden,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

// core components
import Button from 'components/CustomButtons/Button';

import useStyles from './index.style';
import { IMAGES, ROUTES } from '../../../constants';
import { enNamespace, viNamespace } from '../../../languages/config';
import actions from '../../../redux/actions';
import { API_DOMAIN } from '../../../configs';
import CreateAgent from '../../BowserAgent/CreateAgent';

export default function MainNavbar() {
  const classes = useStyles();
  const history = useHistory();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { userInfo } = useSelector((state) => state.auth);

  const { username, avatar, packageId, isAdmin, providerMoney } = userInfo;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const dropdownRef = useRef(null);

  const [openLanguage, setOpenLanguage] = useState(null);
  const handleClickLanguage = (event) => {
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };
  const handleCloseLanguage = () => {
    setOpenLanguage(null);
  };

  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    dispatch(actions.auth.logout());
  };

  const handleGotoHistory = () => {
    history.push(ROUTES.TRANSACTION_HISTORY);
  };

  const handleGotoPaymentManager = () => {
    history.push(ROUTES.ORDER_VIEW);
  };

  const handleOpenVideo = () => {
    history.push(ROUTES.VIDEOS);
  };

  const handleOpenVideoGreenScreen = () => {
    handleCloseProfile();
    history.push(ROUTES.GREEN_SCREEN);
  };

  const handleOpenVideoTemplate = () => {
    handleCloseProfile();
    history.push(ROUTES.CREATE_VIDEO);
  };

  const handleGotoAccountManager = () => {
    handleCloseProfile();
    history.push(ROUTES.MANAGER_ACCOUNT);
  };

  const handleGotoPromotionManager = () => {
    handleCloseProfile();
    history.push(ROUTES.MANAGER_PROMOTION);
  };

  const handleGotoBowserAgent = () => {
    handleCloseProfile();
    history.push(ROUTES.BOWSER_AGENT);
  };

  const handleGotoCmsAgent = () => {
    handleCloseProfile();
    history.push(ROUTES.CMS_AGENT);
  };

  const handleChangeLanguage = (namespace) => async () => {
    await i18n.changeLanguage(namespace);
    localStorage.setItem('i18nextLng', namespace);
    handleCloseLanguage();
  };

  useEffect(() => {
    const i18nextLng = localStorage.getItem('i18nextLng');
    const namespace = i18nextLng === enNamespace ? enNamespace : viNamespace;
    handleChangeLanguage(namespace)();
  }, []);

  const dropdownItem = classNames(classes.dropdownItem);

  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  var list = (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
        <NavLink to={ROUTES.VIDEOS} className={classes.navLink}>
          <img src={IMAGES.house} alt="home-icon" />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to={'/'} className={classes.navLink}>
          <img src={IMAGES.user} alt="user-icon" />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to={'/'} className={classes.navLink}>
          <img src={IMAGES.question} alt="question-icon" />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to={'/'} className={classes.navLink}>
          <img src={IMAGES.nightMode} alt="nightmode-icon" />
        </NavLink>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <div className={managerClasses}>
          <CreateAgent />
        </div>
      </ListItem>
      <ListItem className={classes.listItem}>
        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="language"
            aria-owns={openLanguage ? 'language-menu-list' : null}
            aria-haspopup="true"
            onClick={handleClickLanguage}
            className={classes.buttonLink}
          >
            <ListItemText
              primary={t('language')}
              disableTypography={true}
              className={classes.listItemText}
              secondary={<b className={classes.caret} />}
            />
          </Button>
          <Popper
            open={Boolean(openLanguage)}
            anchorEl={openLanguage}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openLanguage,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list"
                style={{ transformOrigin: '0 0 0' }}
              >
                <Paper ref={dropdownRef} className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleCloseLanguage}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={handleChangeLanguage(viNamespace)}
                        className={dropdownItem}
                      >
                        {t('vi')}
                      </MenuItem>
                      <MenuItem
                        onClick={handleChangeLanguage(enNamespace)}
                        className={dropdownItem}
                      >
                        {t('en')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </ListItem>
      <ListItem className={classes.listItem}>
        <div className={classes.userSettings}>
          <div>
            <div className={classes.usename}>{username}</div>
            <div className={classes.upVip}>
              <Link to={ROUTES.PRICING}>
                {packageId ? t('buyMore') : t('upVip')}
                <img src={IMAGES.star} alt="" />
              </Link>
            </div>
          </div>
          <div className={managerClasses}>
            <div>
              <Button
                color="transparent"
                aria-label="Person"
                aria-owns={openProfile ? 'profile-menu-list' : null}
                aria-haspopup="true"
                onClick={matches ? handleClickProfile : null}
                onMouseEnter={matches ? handleClickProfile : null}
                className={classes.avatar}
              >
                <img
                  src={avatar ? `${API_DOMAIN}${avatar}` : IMAGES.avartaUser}
                  alt=""
                />
              </Button>
            </div>
            <Popper
              open={Boolean(openProfile)}
              anchorEl={openProfile}
              transition
              disablePortal
              placement="bottom"
              className={classNames({
                [classes.popperClose]: !openProfile,
                [classes.popperResponsive]: true,
                [classes.popperNav]: true,
              })}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  id="profile-menu-list"
                  style={{ transformOrigin: '0 0 0' }}
                >
                  <Paper className={classes.dropdown}>
                    <ClickAwayListener onClickAway={handleCloseProfile}>
                      <MenuList role="menu">
                        <MenuItem
                          onClick={handleOpenVideo}
                          className={dropdownItem}
                        >
                          {t('myVideos')}
                        </MenuItem>
                        <MenuItem
                          onClick={handleOpenVideoTemplate}
                          className={dropdownItem}
                        >
                          {t('showVideoTemplate')}
                        </MenuItem>
                        <MenuItem
                          onClick={handleOpenVideoGreenScreen}
                          className={dropdownItem}
                        >
                          {t('showMCGreen')}
                        </MenuItem>
                        <MenuItem
                          onClick={handleGotoHistory}
                          className={dropdownItem}
                        >
                          {t('showHistoryOrder')}
                        </MenuItem>
                        {isAdmin === 1 && (
                          <MenuItem
                            onClick={handleGotoPaymentManager}
                            className={dropdownItem}
                          >
                            {t('paymentManager')}
                          </MenuItem>
                        )}

                        {isAdmin === 1 && (
                          <>
                            <MenuItem
                              onClick={handleGotoAccountManager}
                              className={dropdownItem}
                            >
                              {t('showManagerAccount')}
                            </MenuItem>
                            <MenuItem
                              onClick={handleGotoPromotionManager}
                              className={dropdownItem}
                            >
                              {t('showManagerPromotion')}
                            </MenuItem>
                            <MenuItem
                              onClick={handleGotoBowserAgent}
                              className={dropdownItem}
                            >
                              {t('showBowserAgent')}
                            </MenuItem>
                          </>
                        )}
                        {providerMoney !== -1 && (
                          <MenuItem
                            onClick={handleGotoCmsAgent}
                            className={dropdownItem}
                          >
                            {t('showCmsAgent')}
                          </MenuItem>
                        )}
                        <Divider light />
                        <MenuItem
                          onClick={handleLogout}
                          className={dropdownItem}
                        >
                          {t('logout')}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </ListItem>
    </List>
  );

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.container}>
        <div className={classes.flex} style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Link to={ROUTES.HOME}>
            <img className={classes.logo} src={IMAGES.logo} />
          </Link>
          <Hidden smDown><div><img src="https://i.imgur.com/WOn6Uni.jpg" alt="https://i.imgur.com/WOn6Uni.jpg" style={{
          maxWidth: '500px',
          maxHeight: '55px'
        }}/></div></Hidden>
        </div>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <img src={IMAGES.menuIcon} />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={'right'}
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
