import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';

import classNames from 'classnames';
import apis from '../../apis';
import useStyles from './index.style';

import CustomButton from 'components/CustomButtons/Button';
import CustomOutlinedInput from 'components/CustomInput/CustomOutlinedInput';

const ReportError = () => {
  const classes = useStyles();
  const { t } = useTranslation(['reportError', 'translation']);

  const [showDialogReport, setShowDialogReport] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [alert, setAlert] = useState(null);
  const [disableSend, setDisableSend] = useState(true);

  const hideAlert = () => {
    setAlert(null);
  };

  const onChangeInput = (value, onSet) => {
    if (value === '') setDisableSend(true);
    else setDisableSend(false);
    onSet(value);
  };

  const onSendReport = async () => {
    const params = {
      title: title,
      report_content: content,
    };
    try {
      const data = await apis.resource.reportError(params);
      if (data.error === 0) {
        setShowDialogReport(false);
        setAlert(
          <SweetAlert
            success
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('notification')}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            confirmBtnText={t('close')}
          >
            {t('thankyou')}
          </SweetAlert>,
        );
        setContent('');
        setTitle('');
      } else throw new Error();
    } catch (error) {
      setShowDialogReport(false);
      setContent('');
      setTitle('');
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText={t('close')}
        >
          <p>{t('translation:::serverError')}</p>
        </SweetAlert>,
      );
    }
  };

  return (
    <div className={classes.background}>
      <Button
        onClick={() => setShowDialogReport(true)}
        className={classes.btnReportError}
      >
        {t('reportError')}
      </Button>
      <Dialog
        className={classes.dialogReportError}
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(showDialogReport)}
        keepMounted
        onClose={() => setShowDialogReport(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('reportError')}
          </h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowDialogReport(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <p className={classes.titleContent}>{t('content')}</p>
          <form>
            <CustomOutlinedInput
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                value: title,
                placeholder: t('title'),
                onChange: (e) => {
                  onChangeInput(e.target.value, setTitle);
                },
              }}
            />
            <FormControl classNam={classes.formControl} fullWidth>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={5}
                placeholder={t('description')}
                className={classes.textareaDescription}
                value={content}
                onChange={(e) => {
                  onChangeInput(e.target.value, setContent);
                }}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btnCancel}
            onClick={() => setShowDialogReport(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            className={classes.btnAccept}
            onClick={() => onSendReport()}
            disabled={disableSend}
          >
            {t('send')}
          </Button>
        </DialogActions>
      </Dialog>
      {alert}
    </div>
  );
};
export default ReportError;
