import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

export default makeStyles((theme) => ({
  ...buttonStyle,
  background: {
    '& .sweet-alert': {
      color: '#000',
    },
  },
  btnCreateAgent: {
    padding: '15px',
    fontSize: '14px',
    fontWeight: 400,
    textTransform: 'unset',
  },
}));
