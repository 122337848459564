import { ROUTES } from '../constants';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Home from '../pages/Home';
import PaymentPage from '../pages/Payment';
import PersonPages from '../pages/PersonPage';
import PricingPage from '../pages/Pricing';
import CreateVideo from '../pages/Video/CreateVideo';
import GreenScreen from '../pages/GreenScreen';
import PolicyPage from '../pages/Policy';
import TransactionHistory from '../pages/TransactionHistory';
import OrderView from '../pages/OrderView';
import ManagerAccount from '../pages/ManagerAccount';
import ManagerPromotion from '../pages/ManagerPromotion';
import BowserAgent from '../pages/BowserAgent';
import CmsAgent from '../pages/CmsAgent';
import CreativeEditorSDK from '../pages/CreativeEditorSDK';

const routes = [
  {
    path: ROUTES.LOGIN,
    component: Login,
    exact: true,
    restricted: true,
    isPrivate: false,
  },
  // {
  //   path: ROUTES.REGISTER,
  //   component: Register,
  //   exact: true,
  //   restricted: true,
  //   isPrivate: false,
  // },
  {
    path: ROUTES.HOME,
    component: Home,
    exact: true,
    restricted: false,
    isPrivate: false,
  },
  {
    path: `${ROUTES.PAYMENT}/:id`,
    component: PaymentPage,
    exact: true,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTES.PRICING,
    component: PricingPage,
    exact: true,
    restricted: false,
    isPrivate: false,
  },
  {
    path: ROUTES.VIDEOS,
    component: PersonPages,
    exact: true,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTES.CREATE_VIDEO,
    component: CreateVideo,
    exact: true,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTES.GREEN_SCREEN,
    component: GreenScreen,
    exact: true,
    restricted: false,
    isPrivate: true,
  },
  {
    path: ROUTES.POLICY,
    component: PolicyPage,
    exact: true,
    restricted: false,
    isPrivate: false,
  },
  {
    path: ROUTES.TRANSACTION_HISTORY,
    component: TransactionHistory,
    isPrivate: true,
    restricted: false,
  },
  {
    path: ROUTES.ORDER_VIEW,
    component: OrderView,
    isPrivate: true,
    restricted: false,
  },
  {
    path: ROUTES.MANAGER_PROMOTION,
    component: ManagerPromotion,
    isPrivate: true,
    restricted: false,
  },
  {
    path: ROUTES.MANAGER_ACCOUNT,
    component: ManagerAccount,
    isPrivate: true,
    restricted: false,
  },
  {
    path: ROUTES.BOWSER_AGENT,
    component: BowserAgent,
    isPrivate: true,
    restricted: false,
  },
  {
    path: ROUTES.CMS_AGENT,
    component: CmsAgent,
    isPrivate: true,
    restricted: false,
  },
  {
    path: ROUTES.CREATIVE_EDITOR_SDK,
    component: CreativeEditorSDK,
    isPrivate: true,
    restricted: false,
  },
];

export { routes };
