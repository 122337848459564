import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Table from 'components/Table/Table';

import useStyles from './index.style';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { formatNumber } from 'utils/number';
import apis from 'apis';
import CustomOutlinedInput from 'components/CustomInput/CustomOutlinedInput';
import CustomButton from 'components/CustomButtons/Button';

const PackageCanBuy = ({ getAlert }) => {
  const { t } = useTranslation('cmsAgent');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { packages } = useSelector((state) => state.payment);

  const [alert, setAlert] = useState(null);
  const [listData, setListData] = useState(packages ? packages : []);
  const [idAccount, setIdAccount] = useState('');
  const [valuePackage, setValuePackage] = useState('');
  const [showDialogBuyPackageForAccount, setShowDialogBuyPackageForAccount] =
    useState(false);

  const handleBuyPackageForAccount = (item) => {
    setShowDialogBuyPackageForAccount(true);
    setValuePackage(item.id);
  };

  const onConfirmBuyPackageForAccount = async () => {
    setShowDialogBuyPackageForAccount(false);
    const params = {
      package_id: valuePackage,
      account_id: idAccount,
    };
    try {
      const data = await apis.agent.buyPackageForAccount(params);
      if (data.error === 0) {
        enqueueSnackbar(t('successBuyMoney'), {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      } else {
        enqueueSnackbar(data.msg, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
      setIdAccount('');
    } catch (err) { }
  };

  const tableData = () => {
    if (listData) {
      return listData.map((item, index) => {
        return [
          index + 1,
          item.id,
          item.title,
          formatNumber(item.price),
          formatNumber(item.sale),
          item.numberVideo,
          item.numberMc,
          item.numberVoice,
          item.expireKind,
          <div className={classes.actions}>
            <Tooltip title={t('buyPackage')} placement="top">
              <Button
                aria-label="delete"
                onClick={() => handleBuyPackageForAccount(item)}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Tooltip>
          </div>,
        ];
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    tableData();
  }, [listData]);

  useEffect(() => {
    if (getAlert) getAlert(alert);
  }, [alert]);

  return (
    <div className={classes.background}>
      <Table
        tableHead={[
          {
            minWidth: '50',
            title: 'STT',
          },
          {
            minWidth: '50',
            title: 'ID',
          },
          {
            minWidth: '150',
            title: t('namePackage'),
          },
          {
            minWidth: '150',
            title: t('price'),
          },
          {
            minWidth: '200',
            title: t('sale'),
          },
          {
            minWidth: '150',
            title: t('numberVideo'),
          },
          {
            minWidth: '150',
            title: t('numberMc'),
          },
          {
            minWidth: '150',
            title: t('numberVoice'),
          },
          {
            minWidth: '150',
            title: t('expireDay'),
          },
          {
            minWidth: '150',
            title: t('action'),
          },
        ]}
        tableData={tableData()}
        customCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
        customHeadCellClasses={[
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
      />
      <Dialog
        className={`${classes.dialogCreateOrder} ${classes.dialog}`}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(showDialogBuyPackageForAccount)}
        keepMounted
        onClose={false}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('buyPackage')}
          </h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowDialogBuyPackageForAccount(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <form>
            <InputLabel className={classes.labelInput}>
              {t('accountId')}
            </InputLabel>
            <CustomOutlinedInput
              labelText={t('accountId')}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                value: idAccount,
                placeholder: t('accountId'),
                onChange: (e) => {
                  setIdAccount(e.target.value);
                },
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btnCancel}
            onClick={() => setShowDialogBuyPackageForAccount(false)}
          >
            {t('translation:::cancel')}
          </Button>
          <Button
            className={classes.btnAccept}
            onClick={() => onConfirmBuyPackageForAccount()}
          >
            {t('translation:::accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PackageCanBuy;
