import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Button } from '@material-ui/core';

import apis from '../../../apis';
import useStyles from './index.style.js';

const CreateAgent = () => {
  const classes = useStyles();
  const { t } = useTranslation('bowserAgent');

  const [alert, setAlert] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const hideAlert = () => {
    setAlert(null);
  };

  const handeConfirmOrder = async (item) => {
    setAlert(null);
    try {
      const data = await apis.agent.createAgent();
      const { error, msg } = data;
      if (error === 0) {
        enqueueSnackbar(msg, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      } else {
        enqueueSnackbar(msg, {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    } catch (_) { }
  };

  const createAgent = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        className={classes.alert}
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => handeConfirmOrder()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('accept')}
        cancelBtnText={t('close')}
      >
        <p>{t('wantYou')}</p>
      </SweetAlert>,
    );
    return;
  };

  return (
    <div className={classes.background}>
      <Button onClick={() => createAgent()} className={classes.btnCreateAgent}>
        {t('registerAgent')}
      </Button>
      {alert}
    </div>
  );
};

export default CreateAgent;
