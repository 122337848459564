import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Assignment } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
} from '@material-ui/core';

import classNames from 'classnames';
import apis from 'apis';
import { formatNumber } from 'utils/number';

import useStyles from './index.style';
import actions from '../../redux/actions';

import ListAccountPurchased from './ListAccountPurchased';
import PackageSystem from './PackageSystem';
import PackageCanBuy from './PackageCanBuy';
import PackageTrailGiven from './PackageTrialGiven';
import PackagePurchased from './PackagePurchased';

import CustomOutlinedInput from 'components/CustomInput/CustomOutlinedInput';
import CustomButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import NavPills from 'components/NavPills/NavPills';

const CmsAgent = () => {
  const classes = useStyles();
  const { t } = useTranslation('cmsAgent');
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);
  const { providerMoney } = userInfo;

  const { enqueueSnackbar } = useSnackbar();

  const [alert, setAlert] = useState(null);
  const [showDialogGiftPackage, setShowDialogGiftPackage] = useState(false);
  const [idAccount, setIdAccount] = useState(null);
  const [active, setActive] = useState(0);

  const handelGetAlert = useCallback((item) => {
    setAlert(item);
  });

  const hideAlert = () => {
    setAlert(null);
  };

  const onChange = (index) => {
    setActive(index);
  };

  const onConfirmGiftPackage = async () => {
    try {
      const data = await apis.agent.giftPackage({ account_id: idAccount });
      const { error, msg } = data;
      if (error === 0) {
        enqueueSnackbar(t('successGift'), {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      } else {
        enqueueSnackbar(msg, {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    } catch (err) { }
    setIdAccount('');
  };

  const updateProviderMoney = async (value) => {
    try {
      const res = await apis.auth.updateAccount({ lastMoney: value });
      const { error } = res;
      if (error === 0) {
        dispatch(actions.auth.getUserInfo());
      }
    } catch (_) { }
  };

  const handleBuyPackageTrial = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => onConfirmBuyPackageTrial()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('accept')}
        cancelBtnText={t('close')}
      >
        <p>{t('sureBuyPackage')} ?</p>
      </SweetAlert>,
    );
  };

  const onConfirmBuyPackageTrial = async () => {
    setAlert(null);
    try {
      const data = await apis.agent.buyPackageTrial();
      const { error, msg, lastMoney } = data;
      if (error === 0) {
        enqueueSnackbar(msg, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        updateProviderMoney(lastMoney);
      } else {
        enqueueSnackbar(msg, {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    } catch (err) { }
  };

  return (
    <div className={classes.background}>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h3 className={classes.cardIconTitle}>{t('cmsAgent')}</h3>
        </CardHeader>
        <CardBody>
          <GridContainer className={classes.container}>
            <GridItem xs={12} md={6}>
              <h5>
                {t('money')}: {providerMoney ? formatNumber(providerMoney) : 0}{' '}
                VNĐ
              </h5>
            </GridItem>
            <GridItem xs={12} md={6} className={classes.textAlignRight}>
              <Button onClick={() => handleBuyPackageTrial()}>
                {t('buyPackageTrial')}
              </Button>
              <Button onClick={() => setShowDialogGiftPackage(true)}>
                {t('givePackageTrial')}
              </Button>
            </GridItem>
          </GridContainer>

          <NavPills
            color=""
            active={active}
            onTabChange={onChange}
            tabs={[
              {
                tabButton: t('accountPurchased'),
                tabContent: (
                  <ListAccountPurchased
                    isActive={active === 0}
                  ></ListAccountPurchased>
                ),
              },
              {
                tabButton: t('packageTrialGiven'),
                tabContent: (
                  <PackageTrailGiven isActive={active == 1}></PackageTrailGiven>
                ),
              },
              {
                tabButton: t('packageSystem'),
                tabContent: (
                  <PackageSystem
                    isActive={active === 2}
                    getAlert={handelGetAlert}
                  ></PackageSystem>
                ),
              },
              {
                tabButton: t('packageCanBuy'),
                tabContent: (
                  <PackageCanBuy
                    getAlert={handelGetAlert}
                    isActive={active === 3}
                  ></PackageCanBuy>
                ),
              },
              {
                tabButton: t('packagePurchased'),
                tabContent: (
                  <PackagePurchased
                    getAlert={handelGetAlert}
                    isActive={active === 4}
                  ></PackagePurchased>
                ),
              },
            ]}
          ></NavPills>
        </CardBody>
      </Card>
      <Dialog
        className={`${classes.dialogCreateOrder} ${classes.dialog}`}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(showDialogGiftPackage)}
        keepMounted
        onClose={false}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('givePackageTrial')}
          </h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowDialogGiftPackage(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <form>
            <InputLabel className={classes.labelInput}>
              {t('accountId')}
            </InputLabel>
            <CustomOutlinedInput
              labelText={t('accountId')}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                value: idAccount,
                placeholder: t('accountId'),
                onChange: (e) => {
                  setIdAccount(e.target.value);
                },
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btnCancel}
            onClick={() => setShowDialogGiftPackage(false)}
          >
            {t('translation:::cancel')}
          </Button>
          <Button
            className={classes.btnAccept}
            onClick={() => onConfirmGiftPackage()}
          >
            {t('translation:::accept')}
          </Button>
        </DialogActions>
      </Dialog>
      {alert}
    </div>
  );
};

export default CmsAgent;
