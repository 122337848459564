import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';

import GridContainer from 'components/Grid/GridContainer';
import PricingItem from 'components/PricingItem';

import useStyles from './index.style';
import actions from '../../redux/actions';

const mapPricingTypes = {
  'Miễn Phí': 'free',
  'Tiết Kiệm': 'save',
  'Trải Nghiệm': 'experience',
  'Khuyên Dùng': 'recommend',
  Pro: 'pro',
  'Doanh Nghiệp': 'enterprise',
};

const Pricing = () => {
  const classes = useStyles();

  const { t } = useTranslation('pricing');

  const { fetchingPackage, packages, message } = useSelector(
    (state) => state.payment,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.payment.getListPackages());
  }, []);

  const formatPrice = (value, isSale) => {
    if (isSale && !value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const removeTag = (text) => {
    var regex = /(<([^>]+)>)/gi;
    return text.replace(regex, '');
  };

  const getFeatures = (desc) => {
    return desc.split('\n').map(removeTag);
  };

  if (fetchingPackage) {
    return (
      <div
        className={classNames({
          [classes.background]: true,
          [classes.loading]: true,
        })}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.background}>
      <h3 className={classes.title}>{t('becomeVipMember')}</h3>
      <GridContainer justify="center">
        {packages.map((pkg) => {
          const { id, title, price, sale, desc } = pkg;
          const type = mapPricingTypes[title] || 'save';
          let description = t('recommendPackage');
          if (type === 'free') {
            description = `10 ${t('timeUse')}`;
          } else if (type === 'experience') {
            description = t('experiencePackage');
          } else if (type === 'enterprise') {
            description = t('enterprisePackage');
          }

          return (
            <PricingItem
              key={id}
              type={type}
              title={title}
              description={description}
              price={formatPrice(price)}
              sale={formatPrice(sale, true)}
              features={getFeatures(desc)}
              packageId={id}
            />
          );
        })}
      </GridContainer>
    </div>
  );
};

export default Pricing;
