import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import PropTypes from 'prop-types';
import cx from 'classnames';
import CustomSlider from 'components/CustomSlider';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import styles from './index.style';
import apis from 'apis';

const useStyles = makeStyles(styles);

const Asidebar = ({ setAudioImage }) => {
  const classes = useStyles();
  const { t } = useTranslation(['greenScreen']);

  const [active, setActive] = useState(0);
  const [listMc, setListMc] = useState([]);
  const [selectedMc, setSelectedMc] = useState(0);
  const [selectedClothing, setSelectedClothing] = useState(0);

  const fetchListMc = async () => {
    const res = await apis.resource.getMC();
    const { error, list } = res;
    if (error === 0) {
      setListMc(list);
    }
  };

  useEffect(() => {
    fetchListMc();
  }, []);

  const listClothing = useMemo(() => {
    if (listMc.length == 0 || selectedMc === undefined) return [];
    const listByMc = listMc[selectedMc].list;
    const listByClothing = listByMc[selectedClothing];
    setAudioImage({
      linkAudio: listByClothing.path,
      linkImage: listByClothing.imageThumb,
      linkAudioDemo: listByClothing.linkDemo,
    });
    return listMc[selectedMc].list;
  }, [selectedMc, listMc, selectedClothing]);

  const handleChangeIndex = (index) => {
    setActive(index);
  };

  const handleSelectMc = (index) => {
    setSelectedMc(index);
  };

  const handleSelectClothing = (index) => {
    setSelectedClothing(index);
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderListMC = () => {
    return listMc.map((item, index) => {
      const { name, image } = item;
      const isSelected = index === selectedMc;

      return (
        <div
          className={classes.sliderItem}
          key={index}
          onClick={() => handleSelectMc(index)}
        >
          <Badge
            badgeContent={<CheckIcon className={classes.iconCheck} />}
            color="primary"
            overlap="circle"
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            invisible={!isSelected}
            className={classes.bage}
          >
            <img
              className={cx({
                [classes.avatarImg]: true,
                [classes.itemSelected]: isSelected,
              })}
              src={image}
              alt=""
            />
          </Badge>
          <p className={classes.nameMC}>{name}</p>
        </div>
      );
    });
  };

  const renderListClothing = () => {
    return (
      <GridContainer>
        {listClothing.map((item, index) => {
          const { link } = item;
          const isSelected = index === selectedClothing;

          return (
            <GridItem
              key={`video - ${index}`}
              xs={12}
              md={6}
              className={classes.closeItem}
            >
              <div key={index} onClick={() => handleSelectClothing(index)}>
                <Badge
                  badgeContent={<CheckIcon className={classes.iconCheck} />}
                  color="primary"
                  overlap="circle"
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  invisible={!isSelected}
                  className={classes.bage}
                >
                  <img
                    className={cx({
                      [classes.carouselImg]: true,
                      [classes.itemSelected]: isSelected,
                    })}
                    src={link}
                    alt=""
                  />
                </Badge>
                <p className={classes.nameMC}>{item.name}</p>
              </div>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  return (
    <div className={classes.container}>
      <GridContainer className={classes.gridContainer}>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.contentWrapper}>
            <SwipeableViews
              axis="x"
              index={active}
              onChangeIndex={handleChangeIndex}
              style={{ overflowY: 'hidden' }}
            >
              <div className={classes.tabContent}>
                <>
                  <div className="step1">
                    <div className={classes.headerStep}>
                      <Typography variant="subtitle2" component="p">
                        {t('selectMc')}
                      </Typography>
                    </div>

                    <CustomSlider {...settings}>{renderListMC()}</CustomSlider>
                  </div>
                  <div className="step2">
                    <div className={classes.headerStep}>
                      <Typography variant="subtitle2" component="p">
                        {t('selectClothing')}
                      </Typography>
                    </div>
                    <div>{renderListClothing()}</div>
                  </div>
                </>
              </div>
            </SwipeableViews>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Asidebar;

Asidebar.propTypes = {
  templates: PropTypes.array,
  currentTemplate: PropTypes.object,
  handleSelectTemplate: PropTypes.func,
  handleUpdateTemplate: PropTypes.func,
  listMc: PropTypes.array,
};
