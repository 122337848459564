import { makeStyles } from '@material-ui/core/styles';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import customInputDialogStyle from 'assets/jss/material-dashboard-pro-react/components/customInputDialogStyle';

export default makeStyles((theme) => ({
  ...buttonStyle,
  ...modalStyle(theme),

  background: {
    textAlign: 'center',
  },
  btnReportError: {
    color: '#e52d27',
    marginTop: '15px',
  },

  dialogReportError: {
    ...customInputDialogStyle,
  },
  textareaDescription: {
    fontSize: '16px',
    border: '1px solid #D2D2D2',
    padding: '5px 10px',
  },
  titleContent: {
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: '20px',
    lineHeight: '25px',
  },
}));
