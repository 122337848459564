import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Assignment } from '@material-ui/icons';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NavPills from 'components/NavPills/NavPills';

import BowserAgentForm from './BowerAgentForm';
import useStyles from './index.style.js';
import { AGENT_STATUS } from '../../constants';

const BowserAgent = () => {
  const classes = useStyles();
  const { userInfo } = useSelector((state) => state.auth);
  const { t } = useTranslation('bowserAgent');
  const [active, setActive] = useState(0);
  const [alert, setALert] = useState(null);

  const { isAdmin } = userInfo;

  const onChange = (index) => {
    setActive(index);
  };

  const handelGetAlert = useCallback((item) => {
    setALert(item);
  });

  return (
    <GridContainer className={classes.backgroundTab}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h3 className={classes.cardIconTitle}>{t('agentManager')}</h3>
          </CardHeader>
          <CardBody>
            <NavPills
              color=""
              active={active}
              onTabChange={onChange}
              tabs={[
                {
                  tabButton: t('agentWaiting'),
                  tabContent: (
                    <BowserAgentForm
                      status={AGENT_STATUS.WAITING}
                      isActive={active === 0}
                      getAlert={handelGetAlert}
                    ></BowserAgentForm>
                  ),
                },
                {
                  tabButton: t('agentSuccess'),
                  tabContent: (
                    <BowserAgentForm
                      status={AGENT_STATUS.SUCCESS}
                      isActive={active === 1}
                    ></BowserAgentForm>
                  ),
                },
                {
                  tabButton: t('agentFail'),
                  tabContent: (
                    <BowserAgentForm
                      status={AGENT_STATUS.ERROR}
                      isActive={active === 2}
                      getAlert={handelGetAlert}
                    ></BowserAgentForm>
                  ),
                },
              ]}
            ></NavPills>
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

export default BowserAgent;
