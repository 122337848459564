import React from 'react';
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import styles from './index.style';
const useStyles = makeStyles(styles);

const PaymentATM = ({ t, listBank, selectedBank, onChangeBankSelect, messageError }) => {
  const classes = useStyles();
  return (
    <GridItem style={{ width: '100%' }}>
      <FormLabel className={classes.labelLeftHorizontal}>
        {t('selectBank')}:
      </FormLabel>
      <GridContainer
        style={{
          maxWidth: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        {listBank.map((ele, index) => {
          const { link, bankImage, id } = ele;
          return (
            <div
              className={classNames({
                [classes.imageBanking]: true,
                [classes.activeBorderImage]: id === selectedBank,
              })}
              style={{
                width: 'calc(15% + 30px)',
                padding: 'calc(0.05% + 5px)',
              }}
              value={link}
              key={id}
              onClick={() => onChangeBankSelect(id)}
            >
              <img
                style={{
                  width: '100%',
                }}
                className={classes.imageBanking}
                src={bankImage}
                alt="img-bank"
              />
            </div>
          );
        })}
      </GridContainer>
      <small className={classes.errorMessage}>
        <i>{messageError}</i>
      </small>
    </GridItem>
  );
};

export default PaymentATM;
