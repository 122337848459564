const {
  NODE_ENV,
  PUBLIC_URL,
  REACT_APP_API_DOMAIN,
  REACT_APP_FACEBOOK_APP_ID,
  REACT_APP_FACEBOOK_PAGE_ID,
  REACT_APP_PHONE_NUMBER_CSKH,
} = process.env;
export { NODE_ENV, PUBLIC_URL };

export const API_DOMAIN = REACT_APP_API_DOMAIN;

export const FACEBOOK_APP_ID = REACT_APP_FACEBOOK_APP_ID;

export const FACEBOOK_PAGE_ID = REACT_APP_FACEBOOK_PAGE_ID;

export const PHONE_NUMBER_CSKH = REACT_APP_PHONE_NUMBER_CSKH;

export const PAGE_SIZE = 10;

export const A_WEEK = 7 * 24 * 60 * 60 * 1000;
