import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

const mainContentStyle = () => ({
  container: {
    '& tui-image-editor-download-btn':{
      display:'none',
    }
  },
  videoOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  selectMenu: {
    padding: '10px',
  },
  selectMenuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  textareaContent: {
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: 'rgba(226, 226, 226, 0.2)',
  },
  fullImage: {
    width: '100%',
    borderRadius: '5px',
    paddingTop: '10px',
  },
  tabContent: {
    maxHeight: '290px',
    overflow: 'auto',
  },
  textWarning: {
    color: '#C97070',
    fontSize: '14px',
    textTransform: 'none',
    marginBottom: '16px',
  },
  buttonGroup: {
    position: 'relative',
    margin: '10px 1px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  selectContainer: {
    background: '#FFFFFF',
    border: '1px solid #808080',
    boxSizing: 'border-box',
    borderRadius: '5px',
    width: 'max-content',
    margin: '8px 24px 8px 0px',
  },
  selectFormControl: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'unset',
    },
  },
  selectLangControl: {
    minWidth: '120px',
    backgroundColor: '#F5F5F5',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  selectVoiceControl: {
    minWidth: '240px',
  },
  selectRate: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '24px',
  },
  labelHorizontal: {
    marginRight: '14px',
  },
  slider: {
    width: '100px',
  },
  primaryButton: {
    '&,&:hover,&:focus': {
      backgroundColor: primaryColor[0],
    },
  },
  loadingIcon: {
    width: 'unset !important',
    height: 'unset !important',
    color: '#FFFFFF',
    fontSize: 'unset !important',
  },
  termsAndCondition: {
    color: '#383838',
    '& a': {
      '&,&:hover': {
        color: primaryColor[0],
      },
    },
  },
  uploadContainer: {
    width: '100%',
    padding: '0px 20px',
    textAlign: 'center',
  },
  audioContainer: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    maxWidth: '300px',
    height: '106px',
    overflow: 'hidden',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '40px',
    },
  },
  gridItemImage: {
    padding: '7px !important',
  },
  imageContainer: {
    position: 'relative',
    height: '106px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '10px',
    },
  },
  delButton: {
    padding: '0px',
    minWidth: 'unset',
    position: 'absolute',
    top: '8px',
    right: '8px',
    borderRadius: '50%',
    margin: '0px',
    '&,&:hover,&:focus': {
      color: '#B11603',
      backgroundColor: 'transparent',
    },
    '& svg': {
      marginRight: '0px',
    },
  },
  uploadImageContainer: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    height: '106px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #3D4858',
    borderRadius: '10px',
    '& .MuiSvgIcon-root': {
      fontSize: '40px',
    },
  },
  fileInput: {
    cursor: 'pointer',
    display: 'block',
    height: '100%',
    width: '100%',
    opacity: '0 !important',
    position: 'absolute',
  },
  maxLength: {
    float: 'right',
  },
});

export default mainContentStyle;
