export default {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  PRICING: '/pricing',
  PAYMENT: '/payment',
  VIDEOS: '/videos',
  CREATE_VIDEO: '/videos/template',
  GREEN_SCREEN: '/videos/green-screen',
  POLICY: '/policy',
  TRANSACTION_HISTORY: '/transaction-history',
  ORDER_VIEW: '/order-view',
  MANAGER_ACCOUNT: '/manager-account',
  MANAGER_PACKAGE: '/manager-package',
  MANAGER_PROMOTION: '/manager-promotion',
  BOWSER_AGENT: '/bowser-agent',
  CMS_AGENT: '/cms-agent',
  CREATIVE_EDITOR_SDK: '/creative-editor-sdk',
};
