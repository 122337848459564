import api from './api';

export async function getListVideos(params) {
  const data = await api({
    method: 'GET',
    url: '/video/list',
    params: params,
  });
  return data;
}

export async function deleteVideo(videoId) {
  const data = await api({
    method: 'GET',
    url: `/video/delete/${videoId}`,
  });
  return data;
}

export async function createVideoTemplate(videoData) {
  const data = await api({
    method: 'POST',
    url: '/video/create_template',
    data: videoData,
  });
  return data;
}

export async function createdVideo(params) {
  const data = await api({
    method: 'POST',
    url: '/video/create',
    data: params,
  });
  return data;
}

export async function previewVideo(params) {
  const data = await api({
    method: 'POST',
    url: '/video/preview_template',
    data: params,
  });
  return data;
}

export async function upgradeVideo(params) {
  const data = await api({
    method: 'POST',
    url: '/video/upgradehd',
    data: params,
  });
  return data;
}

export async function faceswapVideo(params) {
  const data = await api({
    method: 'POST',
    url: '/video/faceswap',
    data: params,
  });
  return data;
}

export async function upgradeSHD(params) {
  const data = await api({
    method: 'POST',
    url: '/video/upgradeshd',
    data: params,
  });
  return data;
}
