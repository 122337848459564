import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import CustomOutlinedInput from 'components/CustomInput/CustomOutlinedInput';
import CustomSlider from 'components/CustomSlider';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomButton from 'components/CustomButtons/Button';

import { IMAGES } from '../../../../constants';
import { API_DOMAIN } from '../../../../configs';
import styles from './index.style';
import apis from '../../../../apis';
import SweetAlert from 'react-bootstrap-sweetalert';

const useStyles = makeStyles(styles);

const Asidebar = ({
  templates,
  currentTemplate,
  handleSelectTemplate,
  listMc,
  logo,
  setLogo,
  title,
  setTitle,
  subTitle,
  setSubTitle,
  content,
  setContent,
  videoIntro,
  setVideoIntro,
  videoOutro,
  setVideoOutro,
  selectedMc,
  setSelectedMc,
  selectedClothing,
  setSelectedClothing,
  getTypeTemplate,
  setImageMC,
  templateIndexTui,
  mcIndexTui,
}) => {
  const classes = useStyles();

  const { t } = useTranslation(['createTemplate', 'translation']);

  const [active, setActive] = useState(0);

  const logoRef = useRef(null);
  const introRef = useRef(null);
  const outroRef = useRef(null);

  const [introUploading, setIntroUploading] = useState(false);
  const [introPercent, setIntroPercent] = useState(0);
  const [outroUploading, setOutroUploading] = useState(false);
  const [outroPercent, setOutroPercent] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [showDialogPreview, setShowDialogPreview] = useState(false);
  const [indexType, setIndexType] = useState(0);
  const [activeMCClothing, setActiveMCClothing] = useState(
    mcIndexTui ? mcIndexTui : '',
  );
  // const [imageMC, setImageMC] = useState('');

  const { id: currentTemplateId } = currentTemplate || {};

  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  const listClothing = useMemo(() => {
    if (listMc.length == 0 || selectedMc === undefined) return [];
    return listMc[selectedMc].list;
  }, [selectedMc, listMc]);

  const handleChange = (event, active) => {
    setActive(active);
  };

  const handleChangeIndex = (index) => {
    setActive(index);
  };

  const flexContainerClasses = cx({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: true,
  });

  const handleSelectMc = (index, item) => {
    setSelectedMc(index);
    setSelectedClothing(0);
    setActiveMCClothing(-1);
    setImageMC('');
  };

  const handleSelectClothing = (index, item) => {
    setSelectedClothing(index);
    setImageMC(item.link);
    setActiveMCClothing(index);
  };

  const handleUploadLogo = () => {
    logoRef.current.click();
  };

  const handleLogoChange = async (event) => {
    event.preventDefault();
    let newFile = event.target.files[0];

    if (newFile) {
      const formData = new FormData();
      formData.append('image', newFile);
      try {
        const res = await apis.resource.uploadFile({
          formData,
        });
        const { path } = res || {};
        if (path) {
          setLogo(path);
        }
      } catch (_) { }
    }
  };

  const handleResetLogo = () => {
    setLogo(null);
    logoRef.current.value = null;
  };

  const handleUploadIntro = () => {
    introRef.current.click();
  };

  const handleVideoIntroChange = async (event) => {
    event.preventDefault();
    let newFile = event.target.files[0];

    if (newFile) {
      const formData = new FormData();
      formData.append('image', newFile);
      setIntroUploading(true);
      try {
        const res = await apis.resource.uploadFile({
          formData,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setIntroPercent(percent);
          },
        });
        const { path } = res || {};
        if (path) {
          setVideoIntro(path);
        }
      } catch (_) { }
      setIntroUploading(false);
      setIntroPercent(0);
    }
  };

  const handleUploadOutro = () => {
    outroRef.current.click();
  };

  const handleVideoOutroChange = async (event) => {
    event.preventDefault();
    let newFile = event.target.files[0];

    if (newFile) {
      const formData = new FormData();
      formData.append('image', newFile);
      setOutroUploading(true);
      try {
        const res = await apis.resource.uploadFile({
          formData,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setOutroPercent(percent);
          },
        });
        const { path } = res || {};
        if (path) {
          setVideoOutro(path);
        }
      } catch (_) { }
      setOutroUploading(false);
      setOutroPercent(0);
    }
  };

  const handlePreviewTemplate = async () => {
    const params = {
      mc_id: listClothing[selectedClothing].id,
      template_id: currentTemplate.id,
      title: title,
      subject: subTitle,
      body: content,
      logo: logo,
    };
    try {
      setImageLoading(true);
      const res = await apis.video.previewVideo(params);
      const { data, error } = res || {};
      if (error === 0) {
        setImagePreview(data.path);
      } else throw new Error();
    } catch (error) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText="Đóng"
        >
          <p>{t('translation:::serverError')}</p>
        </SweetAlert>,
      );
    }
    setImageLoading(false);
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderListMC = () => {
    return listMc.map((item, index) => {
      const { name, image } = item;
      const isSelected = index === selectedMc;

      return (
        <div
          className={classes.sliderItem}
          key={index}
          onClick={() => handleSelectMc(index, item)}
        >
          <Badge
            badgeContent={<CheckIcon className={classes.iconCheck} />}
            color="primary"
            overlap="circle"
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            invisible={!isSelected}
            className={classes.bage}
          >
            <img
              className={cx({
                [classes.avatarImg]: true,
                [classes.itemSelected]: isSelected,
              })}
              src={image}
              alt=""
            />
          </Badge>
          <p className={classes.nameMC}>{name}</p>
        </div>
      );
    });
  };

  const listType = [
    {
      type: '',
      name: t('all'),
    },
    {
      type: 1,
      name: t('vertical'),
    },
    {
      type: 2,
      name: t('horizontal'),
    },
    {
      type: 3,
      name: t('square'),
    },
  ];

  const handelChangeType = (type, index) => {
    getTypeTemplate(type);
    setIndexType(index);
  };

  useEffect(() => {
    setActiveMCClothing(mcIndexTui ? mcIndexTui : '');
  }, [mcIndexTui]);

  const renderListTypeTemplate = () => {
    return (
      <ul className={classes.typeTemplate}>
        {listType.map((item, index) => (
          <li
            key={item.type}
            className={indexType === index ? classes.typeActive : ''}
            onClick={() => handelChangeType(item.type, index)}
          >
            {item.name}
          </li>
        ))}
      </ul>
    );
  };

  const renderListClothing = () => {
    return (
      <GridContainer>
        {listClothing.map((item, index) => {
          const { link } = item;
          const isSelected = index === selectedClothing;
          //bật cái này
          // const isSelected = index === activeMCClothing;
          return (
            <GridItem
              key={`video - ${index}`}
              xs={12}
              md={6}
              className={classes.closthingItem}
            >
              <div
                key={index}
                onClick={() => handleSelectClothing(index, item)}
              >
                <Badge
                  badgeContent={<CheckIcon className={classes.iconCheck} />}
                  color="primary"
                  overlap="circle"
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  invisible={!isSelected}
                  className={classes.bage}
                >
                  <img
                    className={cx({
                      [classes.carouselImg]: true,
                      [classes.itemSelected]: isSelected,
                    })}
                    src={link}
                    alt=""
                  />
                </Badge>
                <p className={classes.nameMC}>{item.name}</p>
              </div>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  return (
    <div className={classes.container}>
      <GridContainer className={classes.gridContainer}>
        <GridItem xs={12} sm={12} md={9}>
          <div className={classes.contentWrapper}>
            <SwipeableViews
              axis="x"
              index={active}
              onChangeIndex={handleChangeIndex}
              style={{ overflowY: 'hidden' }}
            >
              <div className={classes.tabContent}>
                <div className={classes.headerStep}>
                  <Typography variant="subtitle2" component="p">
                    {t('step1')}
                  </Typography>
                </div>
                <div className={classes.listTemplates}>
                  {renderListTypeTemplate()}
                  {templates.map((item, index) => {
                    const { id, thumb } = item;
                    const isSelected = id === currentTemplateId;

                    return (
                      <div
                        className={classes.templateContainer}
                        key={index}
                        onClick={() => handleSelectTemplate(index)}
                      >
                        <Badge
                          badgeContent={
                            <CheckIcon className={classes.iconCheck} />
                          }
                          color="primary"
                          overlap="circle"
                          anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                          }}
                          invisible={
                            !isSelected || templateIndexTui.item === -1
                          }
                          className={classes.bage}
                        >
                          <img
                            className={cx({
                              [classes.templateImg]: true,
                              [classes.itemSelected]: isSelected,
                            })}
                            src={`${API_DOMAIN}${thumb}`}
                          />
                        </Badge>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={classes.tabContent}>
                <>
                  <div className="step1">
                    <div className={classes.headerStep}>
                      <Typography variant="subtitle2" component="p">
                        {t('step2')}
                      </Typography>
                    </div>

                    <CustomSlider {...settings}>{renderListMC()}</CustomSlider>
                  </div>
                  <div className="step2">
                    <div className={classes.headerStep}>
                      <Typography variant="subtitle2" component="p">
                        {t('step3')}
                      </Typography>
                    </div>
                    <div>{renderListClothing()}</div>
                  </div>
                </>
              </div>
              <div className={classes.tabContent}>
                <div className={classes.headerStep}>
                  <Typography variant="subtitle2" component="p">
                    {t('step4')}
                  </Typography>
                </div>
                <div className={classes.logoContainer}>
                  {!logo && (
                    <>
                      <img src={IMAGES.logo} />
                      <Typography variant="subtitle2" component="p">
                        {t('useLogoAICLIP')}
                      </Typography>
                    </>
                  )}
                  {logo && (
                    <>
                      <img src={`${API_DOMAIN}${logo}`} />
                      <Button
                        className={classes.resetButton}
                        onClick={handleResetLogo}
                      >
                        {t('reset')}
                      </Button>
                    </>
                  )}

                  <div className={classes.buttonWidthPadding}>
                    <input
                      ref={logoRef}
                      type="file"
                      accept=".jpg,.png"
                      hidden
                      onChange={handleLogoChange}
                    />
                    <Button
                      className={classes.primaryButton}
                      startIcon={<img src={IMAGES.uploadIcon} alt="" />}
                      onClick={handleUploadLogo}
                    >
                      {t('uploadLogo')}
                    </Button>
                  </div>
                </div>
                <form>
                  <CustomOutlinedInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      className: classes.input,
                      value: title,
                      onChange: (e) => {
                        setTitle(e.target.value); // Set undefined to remove the filter entirely
                      },
                      placeholder: 'T1',
                    }}
                  />
                  <CustomOutlinedInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      className: classes.input,
                      value: subTitle,
                      onChange: (e) => {
                        setSubTitle(e.target.value); // Set undefined to remove the filter entirely
                      },
                      placeholder: 'T2',
                    }}
                  />
                  <CustomOutlinedInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      className: classes.input,
                      value: content,
                      onChange: (e) => {
                        setContent(e.target.value); // Set undefined to remove the filter entirely
                      },
                      placeholder: 'T3',
                    }}
                  />
                </form>
                <div className={classes.logoContainer}>
                  <Button
                    className={classes.primaryButton}
                    onClick={handlePreviewTemplate}
                    disabled={imageLoading}
                  >
                    {imageLoading && (
                      <img
                        src={IMAGES.iconLoading}
                        alt=""
                        className={classes.imageLoading}
                      />
                    )}
                    {t('preview')}
                  </Button>
                  {imagePreview && (
                    <div className={classes.zoomIn}>
                      <img
                        src={imagePreview ? `${API_DOMAIN}${imagePreview}` : ''}
                      />
                      <ZoomInIcon
                        onClick={() => {
                          setShowDialogPreview(true);
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className={classes.buttonWrapper}>
                  <input
                    ref={introRef}
                    type="file"
                    accept=".mp4"
                    hidden
                    onChange={handleVideoIntroChange}
                  />
                  <Button
                    className={classes.greyButton}
                    startIcon={<img src={IMAGES.uploadIcon} alt="" />}
                    onClick={handleUploadIntro}
                  >
                    {introUploading
                      ? t('uploadingPercent', { percent: introPercent })
                      : t('uploadVideoIntro')}
                  </Button>
                  {videoIntro !== null && (
                    <Typography className={classes.textSuccess}>
                      {t('successfulUpload')}
                    </Typography>
                  )}
                </div>
                <div className={classes.buttonWrapper}>
                  <input
                    ref={outroRef}
                    type="file"
                    accept=".mp4"
                    hidden
                    onChange={handleVideoOutroChange}
                  />
                  <Button
                    className={classes.greyButton}
                    startIcon={<img src={IMAGES.uploadIcon} alt="" />}
                    onClick={handleUploadOutro}
                  >
                    {outroUploading
                      ? t('uploadingPercent', { percent: outroPercent })
                      : t('uploadVideoOutro')}
                  </Button>

                  {videoOutro !== null && (
                    <Typography className={classes.textSuccess}>
                      {t('successfulUpload')}
                    </Typography>
                  )}
                </div>

                {/* <div className={classes.buttonWidthPadding}>
                  <Button
                    className={cx({
                      [classes.primaryButton]: true,
                      [classes.draftButton]: true,
                    })}
                    startIcon={<img src={IMAGES.playArrowIcon} alt="" />}
                  >
                    {t('preview')}
                  </Button>
                </div> */}
              </div>
            </SwipeableViews>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Tabs
            classes={{
              root: classes.root,
              fixed: classes.fixed,
              flexContainer: flexContainerClasses,
              indicator: classes.displayNone,
            }}
            value={active}
            onChange={handleChange}
          >
            {[
              {
                tabButton: t('tabTemplate'),
                tabIcon: (
                  <img
                    src={
                      active === 0
                        ? IMAGES.activeTemplateIcon
                        : IMAGES.templateIcon
                    }
                  />
                ),
              },
              {
                tabButton: t('tabMC'),
                tabIcon: (
                  <img
                    src={active === 1 ? IMAGES.activeMcIcon : IMAGES.mcIcon}
                  />
                ),
              },
              {
                tabButton: 'MultiMedia',
                tabIcon: (
                  <img
                    src={
                      active === 2
                        ? IMAGES.activeMultimediaIcon
                        : IMAGES.multimediaIcon
                    }
                  />
                ),
              },
            ].map((prop, key) => {
              var icon = {};
              if (prop.tabIcon !== undefined) {
                icon['icon'] = prop.tabIcon;
              }
              const pillsClasses = cx({
                [classes.pills]: true,
                [classes.horizontalPills]: true,
                [classes.pillsWithIcons]: prop.tabIcon !== undefined,
              });
              const pllsSelectedClasses = cx({
                [classes.pillsSelected]: true,
                [classes.pillsBorderTopLeft]: key === 0,
              });

              return (
                <Tab
                  label={prop.tabButton}
                  key={key}
                  {...icon}
                  classes={{
                    root: pillsClasses,
                    selected: pllsSelectedClasses,
                  }}
                />
              );
            })}
          </Tabs>
        </GridItem>
      </GridContainer>
      <Dialog
        className={classes.dialogPreview}
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(showDialogPreview)}
        keepMounted
        onClose={() => setShowDialogPreview(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('preview')}
          </h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowDialogPreview(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <img
            src={imagePreview ? `${API_DOMAIN}${imagePreview}` : ''}
            alt="prevew"
          />
        </DialogContent>
      </Dialog>
      {alert}
    </div>
  );
};
export default Asidebar;

Asidebar.propTypes = {
  templates: PropTypes.array,
  currentTemplate: PropTypes.object,
  handleSelectTemplate: PropTypes.func,
  listMc: PropTypes.array,
  logo: PropTypes.string,
  setLogo: PropTypes.func,
  title: PropTypes.string,
  setTitle: PropTypes.func,
  subTitle: PropTypes.string,
  setSubTitle: PropTypes.func,
  content: PropTypes.string,
  setContent: PropTypes.func,
  videoIntro: PropTypes.string,
  setVideoIntro: PropTypes.func,
  videoOutro: PropTypes.string,
  setVideoOutro: PropTypes.func,
  selectedMc: PropTypes.number,
  setSelectedMc: PropTypes.func,
  selectedClothing: PropTypes.number,
  setSelectedClothing: PropTypes.func,
};
