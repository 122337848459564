import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import customInputDialogStyle from 'assets/jss/material-dashboard-pro-react/components/customInputDialogStyle';

export default makeStyles((theme) => ({
  ...buttonStyle,
  ...modalStyle(theme),
  dialog: {
    ...customInputDialogStyle,
  },
  background: {
    '& .MuiTableHead-root': {
      background: '#eaeced',
    },
  },
  center: {
    border: '1px solid #DDD !important',
    textAlign: 'center',
  },
  bold: {
    fontWeight: '400',
  },
  tablePagination: {
    width: '100%',
    float: 'right',
  },
  actions: {
    '& button': {
      minWidth: 'auto',
    },
  },
}));
