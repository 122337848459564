import api from './api';

export async function getListAgents(params) {
  const data = await api({
    method: 'GET',
    url: '/provider/list',
    params,
  });
  return data;
}

export async function updateAgents(params) {
  const data = await api({
    method: 'POST',
    url: '/provider/update',
    data: params,
  });
  return data;
}

export async function createAgent(params) {
  const data = await api({
    method: 'GET',
    url: '/provider/create',
    params,
  });
  return data;
}

export async function getListAccount(params) {
  const data = await api({
    method: 'GET',
    url: '/provider/account/list',
    params,
  });
  return data;
}

export async function buyPackageForAccount(params) {
  const data = await api({
    method: 'GET',
    url: '/provider/bypackage_for_account',
    params,
  });
  return data;
}

export async function buyMoney(params) {
  const data = await api({
    method: 'GET',
    url: '/provider/buy_money',
    params,
  });
  return data;
}

export async function giftPackage(params) {
  const data = await api({
    method: 'GET',
    url: '/provider/gift_package',
    params,
  });
  return data;
}

export async function buyPackageTrial(params) {
  const data = await api({
    method: 'GET',
    url: '/provider/by_gift',
    params,
  });
  return data;
}

export async function listGift(params) {
  const data = await api({
    method: 'GET',
    url: '/provider/listgift',
    params,
  });
  return data;
}
