import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  card: {
    width: '280px',
    margin: '40px 11px 30px',
    '@media (max-width: 612px)': {
      marginTop: '0px !important',
      marginBottom: '20px !important',
    },
  },
  cardBody: {
    paddingBottom: '0px',
    '& ul': {
      maxWidth: 'unset',
      marginBottom: '0px',
      '& li': {
        borderBottom: 'none',
        padding: '5px 0px',
        textAlign: 'start',
        display: 'flex',
        '& p': {
          margin: '0px',
        },
      },
    },
  },
  experienceCard: {
    backgroundColor: '#2091F9',
    marginTop: '0px',
    '& $cardBody': {
      paddingTop: '22px',
      '@media (max-width: 612px)': {
        paddingTop: '15px',
      },
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '28px',
    letterSpacing: '-0.3px',
    color: '#474747',
  },
  description: {
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '-0.3px',
    color: '#6C6C6C',
    textTransform: 'none',
  },
  divider: {
    height: '0.5px',
    backgroundColor: '#BFBFBF',
    borderRadius: '2px',
    margin: '0px 10px',
  },
  experienceDivider: {
    backgroundColor: '#4FA2FF',
  },
  price: {
    fontSize: '52px',
    fontWeight: 'bold',
    letterSpacing: '-0.3px',
    color: '#BCBCBC',
    margin: '0px',
  },
  priceFontSmall: {
    fontSize: '36px',
  },
  oldPrice: {
    textDecoration: 'line-through',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '19px',
    margin: '10px 0px 0px',
  },
  currency: {
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '-0.3px',
    color: '#6C6C6C',
    margin: '0px 10px',
    textAlign: 'right',
  },
  listStyle: {
    position: 'relative',
    width: '24px',
    height: '24px',
    '& .MuiSvgIcon-root': {
      top: '5px',
    },
  },
  feature: {
    color: '#6C6C6C',
  },
  infinityIcon: { marginLeft: '5px' },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  btnBuy: {
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#CECECE',
    color: '#FFFFFF',
    '&:hover,&:focus': {
      backgroundColor: '#CECECE',
      color: '#FFFFFF',
    },
  },
  saveButton: {
    backgroundColor: '#81BBF0',
    '&:hover,&:focus': {
      backgroundColor: '#81BBF0',
    },
  },
  experienceButton: {
    backgroundColor: '#FFFFFF',
    color: '#3157A7',
    '&:hover,&:focus': {
      color: '#3157A7',
      backgroundColor: '#FFFFFF',
    },
  },
  recommendButton: {
    backgroundColor: '#2091F9',
    '&:hover,&:focus': {
      backgroundColor: '#2091F9',
    },
  },
  proButton: {
    backgroundColor: '#2168AA',
    '&:hover,&:focus': {
      backgroundColor: '#2168AA',
    },
  },
  enterpriseButton: {
    backgroundColor: '#3157A7',
    '&:hover,&:focus': {
      backgroundColor: '#3157A7',
    },
  },
  freeTextColor: {
    color: '#474747',
  },
  saveTextColor: {
    color: '#73B0E9',
  },
  experienceTextColor: {
    color: '#FFFFFF',
  },
  recommendTextColor: {
    color: '#2091F9',
  },
  proTextColor: {
    color: '#2168AA',
  },
  enterpriseTextColor: {
    color: '#3157A7',
  },
  whiteTextColor: {
    color: '#FFFFFF',
  },
  priceNumberText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  priceNumber: {
    fontWeight: 'bold',
    fontSize: '52px',
    lineHeight: '73px',
  },
  typeCurrentcy: {
    fontWeight: 'bold',
    fontSize: '34px',
    marginLeft: '-10px',
  },
}));
