import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import apis from '../../../apis';

import PromotionForm from '../PromotionForm';

const UpdatePromotion = ({
  visible,
  onCloseDialog,
  promotionDetail,
  updateProtion,
}) => {
  const { t } = useTranslation('managerPromotion');
  const { enqueueSnackbar } = useSnackbar();

  const onUpdatePromotion = async ({
    id,
    sale,
    packageId,
    startDate,
    endDate,
    active,
  }) => {
    const promotionData = {
      id: id,
      package_id: packageId,
      sale: sale,
      start_date: startDate,
      end_date: endDate,
      active: active,
    };

    try {
      const data = await apis.promotion.updatePromotion(promotionData);
      console.log({ data });
      const { error, insert } = data;
      if (error === 0) {
        updateProtion(insert);
        enqueueSnackbar(t('updateSuccess'), {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      } else {
        enqueueSnackbar(t('updateFail'), {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    } catch (error) { }
  };

  return (
    <PromotionForm
      visible={visible}
      onCloseDialog={onCloseDialog}
      promotionDetail={promotionDetail}
      onConfirm={onUpdatePromotion}
      title={t('edit')}
    />
  );
};

export default UpdatePromotion;
