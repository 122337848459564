import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import Table from 'components/Table/Table';

import useStyles from './index.style';

import classNames from 'classnames';
import apis from 'apis';
import { formatNumber } from 'utils/number';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustomButton from 'components/CustomButtons/Button';
import SweetAlert from 'react-bootstrap-sweetalert';

const PackageSystem = ({ isActive, getAlert }) => {
  const { t } = useTranslation('cmsAgent');
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [alert, setAlert] = useState(null);

  const [listData, setListData] = useState(null);
  const [valuePackage, setValuePackage] = useState('');
  const [valueNamePackage, setValueNamePackage] = useState('');
  const [showDialogBuyMoney, setShowDialogBuyMoney] = useState('');
  const [disabled, setDisable] = useState(false);

  const hideAlert = () => {
    setAlert(null);
  };

  const getListData = async (params) => {
    try {
      const data = await apis.payment.getListPackages({ type: 'daily' });
      const { error, list } = data;
      if (error === 0) {
        setListData(list);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (!isActive || listData) return;
    getListData();
  }, [isActive, listData]);

  const handleBuyMoney = (item) => {
    setValuePackage(item.id);
    setValueNamePackage(item.title);
    setShowDialogBuyMoney(true);
  };

  const onConfirmBuyMoney = async () => {
    const params = {
      package_id: valuePackage,
    };
    setDisable(true);
    try {
      const data = await apis.agent.buyMoney(params);
      setShowDialogBuyMoney(false);
      if (data.error === 0) {
        enqueueSnackbar(data.msg, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      } else {
        setAlert(
          <SweetAlert
            warning
            showCancel
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('notification')}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText={t('accept')}
            cancelBtnText={t('close')}
          >
            <p>{data.msg}</p>
          </SweetAlert>,
        );
      }
    } catch (err) { }
    setDisable(false);
  };

  useEffect(() => {
    if (getAlert) getAlert(alert);
  }, [alert]);

  const tableData = () => {
    if (listData) {
      return listData.map((item, index) => {
        return [
          index + 1,
          item.id,
          item.title,
          formatNumber(item.price),
          formatNumber(item.sale),
          item.numberVideo,
          item.numberMc,
          item.numberVoice,
          item.expireKind,
          <div className={classes.actions}>
            <Tooltip title={t('buyMoney')} placement="top">
              <Button
                aria-label="delete"
                onClick={() => {
                  handleBuyMoney(item);
                }}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Tooltip>
          </div>,
        ];
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    tableData();
  }, [listData]);

  return (
    <div className={classes.background}>
      <Table
        tableHead={[
          {
            minWidth: '50',
            title: 'STT',
          },
          {
            minWidth: '50',
            title: 'ID',
          },
          {
            minWidth: '150',
            title: t('namePackage'),
          },
          {
            minWidth: '150',
            title: t('price'),
          },
          {
            minWidth: '200',
            title: t('sale'),
          },
          {
            minWidth: '150',
            title: t('numberVideo'),
          },
          {
            minWidth: '150',
            title: t('numberMc'),
          },
          {
            minWidth: '150',
            title: t('numberVoice'),
          },
          {
            minWidth: '150',
            title: t('expireDay'),
          },
          {
            minWidth: '150',
            title: t('action'),
          },
        ]}
        tableData={tableData()}
        customCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
        customHeadCellClasses={[
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
      />
      <Dialog
        className={`${classes.dialogCreateOrder} ${classes.dialog}`}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(showDialogBuyMoney)}
        keepMounted
        onClose={false}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('buyPackage')}
          </h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowDialogBuyMoney(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <p>
            {t('sureBuyMoney')} <b>{valueNamePackage} ?</b>
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btnCancel}
            onClick={() => setShowDialogBuyMoney(false)}
          >
            {t('translation:::cancel')}
          </Button>
          <Button
            className={classes.btnAccept}
            onClick={() => onConfirmBuyMoney()}
            disabled={disabled}
          >
            {t('translation:::accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PackageSystem;
