import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import customInputDialogStyle from 'assets/jss/material-dashboard-pro-react/components/customInputDialogStyle';

export default makeStyles((theme) => ({
  ...buttonStyle,
  ...modalStyle(theme),
  dialog: {
    ...customInputDialogStyle,
  },
  background: {
    '& .MuiTab-textColorInherit': {
      borderRadius: '0 !important',
      border: '1px solid #3157A7',
      fontWeight: '400',
    },
    '& .MuiTabs-indicator ': {
      height: '0px',
    },
    '& .Mui-selected': {
      border: '1px solid #3157A7',
      background: '#3157A7 !important',
      color: '#fff',
      fontWeight: '400',
    },
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  container: {
    position: 'relative',
  },
  textAlignRight: {
    textAlign: 'right',
    '& button': {
      background: '#3157A7',
      marginLeft: '15px',
      color: '#fff',
      border: '1px solid #3157A7',
      fontWeight: 400,
      '&:hover': {
        background: '#fff',
        color: '#3157A7',
      },
    },
  },
  labelInput: {
    marginBottom: '10px',
  },
}));
