import api from './api';

export async function getListAccount(params) {
  const data = await api({
    method: 'GET',
    url: '/account/list',
    params,
  });
  return data;
}

export async function updateAccount(params) {
  const data = await api({
    method: 'POST',
    url: '/account/update',
    data: params,
  });
  return data;
}
