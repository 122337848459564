import { Button } from '@material-ui/core';
import apis from 'apis';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IMAGES } from '../../../constants';
import useStyles from './index.style';

const Footer = () => {
  const { t, i18n } = useTranslation(['footer']);

  const [valueContact, setValueContact] = useState('');
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const changeLanguage = (namespace) => async () => {
    await i18n.changeLanguage(namespace);
    localStorage.setItem('i18nextLng', namespace);
  };

  useEffect(() => {
    changeLanguage('vi')();
  }, []);
  const classes = useStyles();

  const hideAlert = () => {
    setAlert(null);
  };

  const handelContactNow = async () => {
    try {
      const params = {
        phone: valueContact,
      };
      if (!valueContact) {
        setAlert(
          <SweetAlert
            danger
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('notification')}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
          >
            {t('enterPhone')}
          </SweetAlert>,
        );
        return;
      }
      setLoading(true);
      const data = await apis.auth.contactUsNow(params);
      const { error, errorMsg } = data;
      if (error === 0) {
        setAlert(
          <SweetAlert
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('notification')}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
          >
            {t('thankYou')}
          </SweetAlert>,
        );
      } else {
        setAlert(
          <SweetAlert
            danger
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('notification')}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
          >
            {errorMsg}
          </SweetAlert>,
        );
      }
      setLoading(false);
      setValueContact('');
    } catch (_) { }
  };

  return (
    <div className={classes.Footer}>
      <div className={classes.firstColumn}>
        <div className={classes.titleFooter}>
          <div className={classes.textTitleFooter}>
            <h1 className={classes.titleH1Footer}>
              {t('titleH1')} <br /> {t('titleH1Footer')}
            </h1>
            <img
              className={classes.titleFooterImg}
              src={IMAGES.imgMcFooter}
              alt="img-footer"
            />
          </div>
          <p className={classes.titleFooterP}>
            {t('titleP')} <br /> {t('titlePFooter')}
          </p>
        </div>
        <div className={classes.formContact}>
          <input
            className={classes.formContactInput}
            type="text"
            placeholder={t('enterAddress')}
            value={valueContact}
            onChange={(e) => setValueContact(e.target.value)}
          />
          <Button
            type="submit"
            className={classes.formContactButton}
            onClick={() => handelContactNow()}
          >
            {loading && <img src={IMAGES.iconLoading} alt="loading" />}
            {t('contactNow')}
          </Button>
        </div>
        <div className={classes.decor}>
          <hr className={classes.decorHr} />
        </div>
        <ul className={classes.listSupport}>
          <li className={classes.listSupportLi}>
            <div className={classes.contact}>
              <ul className={classes.listContact}>
                <h4 className={classes.footerH4}>
                  {t('footerH4')} <br className={classes.hidden} />
                </h4>
                <li className={classes.listSupportLiChild}>
                  <a className={classes.listSupportLiA} href="dia-chi">
                    <i
                      className={`${classes.icon} fas fa-map-marker-alt`}
                      style={{ padding: '4px 10px' }}
                    ></i>
                    <span>
                      {t('101B1NKT')} <br className={classes.hidden} />
                    </span>
                  </a>
                </li>
                <li className={classes.listSupportLiChild}>
                  <a className={classes.listSupportLiA} href="dia-chi">
                    <i
                      className={`${classes.icon} fas fa-book`}
                      style={{ padding: '4px 10px' }}
                    ></i>
                    <span>
                      {t('gpkd')} <br className={classes.hidden} />
                    </span>
                  </a>
                </li>
                <li className={classes.listSupportLiChild}>
                  <a className={classes.listSupportLiA} href="https://m.me/aiclipai" target="_blank">
                    <i
                      className={`${classes.icon} fas fa-globe`}
                      style={{ padding: '5px 10px' }}
                    ></i>
                    Liên hệ Fanpage
                  </a>
                </li>
                <li className={classes.listSupportLiChild}>
                  <a
                    className={classes.listSupportLiA}
                    href="mailto:ceo@aiclip.ai"
                  >
                    <i
                      className={`${classes.icon} fas fa-envelope`}
                      style={{ padding: '5px 10px' }}
                    ></i>
                    ceo@aiclip.ai
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className={classes.listSupportLi}>
            <div className={classes.introduceFooter}>
              <ul className={classes.listIntroduce}>
                <h4
                  className={classes.footerH4}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {t('introduce')}
                </h4>
                <li className={classes.listIntroduceLi}>
                  <a
                    className={classes.listSupportLiA}
                    href="gioi-thieu"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {t('aboutUs')}
                  </a>
                </li>
                <li className={classes.listIntroduceLi}>
                  <a className={classes.listSupportLiA} href="dich-vu">
                    {t('service')}
                  </a>
                </li>
                <li className={classes.listIntroduceLi}>
                  <a className={classes.listSupportLiA} href="du-an">
                    {t('project')}
                  </a>
                </li>
                <li className={classes.listIntroduceLi}>
                  <a className={classes.listSupportLiA} href="lien-he">
                    {t('contact')}
                  </a>
                </li>
                <li className={classes.listIntroduceLi}>
                  <a
                    className={classes.listSupportLiA}
                    href="/policy"
                    target="_blank"
                  >
                    {t('policy')}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className={classes.listSupportLi}>
            <div className={classes.follow}>
              <ul className={classes.listFollow}>
                <h4 className={classes.footerH4} style={{ whiteSpace: 'pre' }}>
                  Follow Us
                </h4>
                <li className={classes.listFollowLi}>
                  <Link
                    className={classes.listSupportLiA}
                    to={{ pathname: 'https://bit.ly/3CsAwmt' }}
                    target="_blank"
                  >
                    Fanpage
                  </Link>
                </li>
                <li className={classes.listFollowLi}>
                  <Link
                    className={classes.listSupportLiA}
                    to={{ pathname: 'https://bit.ly/3AsIo5E' }}
                    target="_blank"
                  >
                    Facebook
                  </Link>
                </li>
                <li className={classes.listFollowLi}>
                  <Link
                    className={classes.listSupportLiA}
                    to={{ pathname: 'https://bit.ly/3jyVffG' }}
                    target="_blank"
                  >
                    Youtube
                  </Link>
                </li>
                <li className={classes.listFollowLi}>
                  <Link
                    className={classes.listSupportLiA}
                    to={{ pathname: 'https://bit.ly/3ApBLB1' }}
                    target="_blank"
                  >
                    Tiktok
                  </Link>
                </li>
                <li className={classes.listFollowLi}>
                  <Link
                    className={classes.listSupportLiA}
                    to={{ pathname: 'https://bit.ly/3iBOZoh' }}
                    target="_blank"
                  >
                    Zalo
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div className={classes.boxAnnounced}>
          <p className={classes.end}>2019 © Aiclip. All rights reserved.</p>
          <a
            href="http://online.gov.vn/Home/WebDetails/88757"
            target="_blank"
            rel="noreferrer"
          >
            <img src={IMAGES.announced} alt="bộ công thương" />
          </a>
        </div>
      </div>
      <img className={classes.imgDecor} src={IMAGES.qrCode} alt="img-qr-code" />
      <div className={classes.secondColumn}>
        <img
          className={classes.footerSecondColImgFirst}
          src={IMAGES.logo}
          alt="img-banner"
        />
        <div className={classes.decorRadius}></div>
      </div>
      {alert}
    </div>
  );
};

export default Footer;
