import { makeStyles } from '@material-ui/core';
import { container } from 'assets/jss/material-dashboard-pro-react';

import shutterstock from 'assets/img/shutterstock.jpeg';

export default makeStyles(() => ({
  background: {
    backgroundImage: `url(${shutterstock})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  loading: {
    paddingBottom: '30px',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    ...container,
  },
  title: {
    textAlign: 'center',
    marginBottom: '40px',
    color: '#FFFFFF',
    fontSize: '18px',
    '@media (max-width: 912px)': {
      marginBottom: '15px',
    },
  },
}));
