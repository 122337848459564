import {
  container,
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor,
  primaryColor,
  dangerColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.js';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

const paymentPageStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  ...buttonStyle,
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
  },
  container: {
    ...container,
    position: 'relative',
    zIndex: '3',
    // paddingTop: "23vh"
    color: grayColor[0],
  },
  cardSignup: {
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(' +
      hexToRgb(blackColor) +
      ', 0.14), 0 6px 30px 5px rgba(' +
      hexToRgb(blackColor) +
      ', 0.12), 0 8px 10px -5px rgba(' +
      hexToRgb(blackColor) +
      ', 0.2)',
    padding: '40px 0px',
    marginTop: '15vh',
    marginBottom: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '60%',
  },
  cardBody: {
    '@media (max-width: 768px)': {
      padding: '10px',
    },
    '@media (max-width: 992px)': {
      padding: '15px',
    },
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  relative: {
    position: 'relative',
  },
  form: {
    padding: '0',
    position: 'relative',
    marginTop: '30px',
  },
  socialTitle: {
    fontSize: '18px',
  },
  inputAdornment: {
    marginRight: '18px',
    position: 'relative',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  customFormControlClasses: {
    margin: '0 12px',
  },
  checkboxLabelControl: {
    margin: '0',
  },
  checkboxLabel: {
    fontSize: '0.875rem',
    marginLeft: '6px',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.26)',
  },
  img: {
    verticalAlign: 'middle',
    maxWidth: '100%',
    border: '0',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  diviTitle: {
    margin: '0px',
    width: '70%',
    height: '7px',
    background: 'linear-gradient(90deg, #2CA8E0 0%, #2E4396 100%)',
    position: 'relative',

    '&:after': {
      top: 0,
      right: 0,
      width: 0,
      border: '7px solid transparent',
      content: "''",
      position: 'absolute',
      borderTopColor: 'white',
      borderRightColor: 'white',
    },
  },
  diviBottom: {
    height: '60px',
    background: ' linear-gradient(90deg, #2CA8E0 0%, #2E4396 100%)',
  },
  subTitle: {
    fontSize: '14px',
    textTransform: 'uppercase',
    color: primaryColor[1],
    margin: 0,
  },
  title: {
    fontSize: '36px',
    margin: 0,
    textTransform: 'uppercase',
  },
  grayColor: {
    color: grayColor[0],
    marginBottom: '15px',
  },
  questionContent: {
    color: grayColor[0],
    marginTop: '36px',
  },
  labelBold: {
    fontWeight: 500,
  },
  labelBottom: {
    position: 'absolute',
    bottom: 0,
  },
  labelLeftHorizontal: {
    color: grayColor[0],
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    marginRight: '0',
  },
  selectMenu: {
    padding: '10px',
  },

  imageBanking: {
    border: '1px solid white',
    borderRadius: '5px',
    transition: '0.5s',
  },
  activeBorderImage: {
    border: '1px solid aquamarine',
    borderRadius: '15px',
    transform: 'scale(1.4)',
  },
  selectMenuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
    },
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  formItem: {
    marginBottom: '20px',
  },
  formItemLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  formItemValue: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: primaryColor[0],
  },
  backgroundPayment: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    overflow: 'hidden',
    transition: '0.5s',
  },
  errorMessage: {
    color: dangerColor[0],
  },
  loading: {
    color: whiteColor,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default paymentPageStyle;
