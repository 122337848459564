import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

export default makeStyles((theme) => ({
  bg: {
    width: '100%',
    overflow: 'hidden auto !important',
    '& .tui-image-editor-download-btn': {
      display: 'none !important',
    },
    '& .tui-image-editor-submenu': {
      top: '110% !important',
    },
  },
  ...buttonStyle,
  customBtn: {
    marginTop: '15px',
  },
  btnConfirm: {
    background: '#000',
  },
  btnUpload: {
    position: 'relative',
    padding: '5px 15px',
    border: '1px solid #999',
    marginLeft: '15px',
  },
  customInputFile: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
  },
  noteText: {
    color: '#C97070',
    fontSize: '15px',
    marginTop: '15px',
    fontWeight: 500,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));
