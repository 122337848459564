import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Done, FileCopy } from '@material-ui/icons';

import useStyles from './index.style';

const Copyable = ({ text, id }) => {
  const [done, setDone] = useState(false);
  const classes = useStyles();

  const handleCopy = () => {
    const range = document.createRange();
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    setDone(true);
    setTimeout(() => setDone(false), 1000);
  };

  return (
    <div className={classes.wrapper}>
      <div id={id} className={classes.text}>
        {text}
      </div>
      {done ? (
        <IconButton color="primary">
          <Done />
        </IconButton>
      ) : (
        <IconButton color="primary" onClick={handleCopy}>
          <FileCopy />
        </IconButton>
      )}
    </div>
  );
};

export default Copyable;

Copyable.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
