import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  wrapper: {
    textAlign: 'center',
  },
  icon: {
    color: '#EEA236',
    fontSize: 100,
  },
  text: {
    marginBottom: 16,
  },
}));
