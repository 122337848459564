import { makeStyles } from '@material-ui/core';
import { container, cardTitle } from 'assets/jss/material-dashboard-pro-react';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import customInputDialogStyle from 'assets/jss/material-dashboard-pro-react/components/customInputDialogStyle';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle';

export default makeStyles((theme) => ({
  background: {
    padding: '0 15px',
    '& .MuiTableHead-root': {
      background: '#eaeced',
    },
  },
  container: {
    ...container,
  },
  ...buttonStyle,
  ...modalStyle(theme),
  ...customSelectStyle,

  dialog: {
    textAlign: 'left',
    ...customInputDialogStyle,
    '& .MuiSelect-select': {
      padding: '5px 0',
      textAlign: 'left',
      textTransform: 'unset',
      fontSize: '14px',
    },
  },
  labelExpiryDate: {
    fontSize: '14px',
    color: '#AAAAAA',
    textAlign: 'left',
    paddingBottom: '10px',
  },
  customFormControlClasses: {
    '& label': {
      fontSize: '16px',
    },
  },
  actions: {
    '& button': {
      minWidth: 'auto',
    },
  },
  selectFullWidth: {
    width: '100% !important',
    '& .MuiInput-root': {
      width: '100% !important',
    },
  },
  dialogCreateOrder: {
    width: 'auto',
    '& .MuiDialog-paperScrollPaper': {
      width: '50%',
    },
  },
  boxSearch: {
    display: 'flex',
    marginBottom: '25px',
    marginTop: '15px',
    '& .MuiOutlinedInput-input': {
      padding: '7px 15px',
    },
  },
  btnSearch: {
    border: '1px solid #3157A7',
    background: '#3157A7',
    color: '#fff',
    padding: '7px 20px 3px 20px',
    marginLeft: '15px',
    '&:hover': {
      color: '#3157A7',
    },
  },
  inputSearch: {
    border: '1px solid #c4c4c4',
    padding: '0px 15px',
    marginRight: '15px',
    width: '20%',
    '&:before, &:after': {
      display: 'none',
    },
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  center: {
    border: '1px solid #DDD !important',
    textAlign: 'center',
  },
  bold: {
    fontWeight: '400',
  },
  tablePagination: {
    width: '100%',
  },
}));
