const customInputDialogStyle = {
  '& .MuiFormControl-fullWidth': {
    paddingTop: 0,
    '& label': {
      fontSize: '17px',
      top: '8px',
    },
  },
  '& .MuiInputBase-fullWidth': {
    marginBottom: '10px',
    fontSize: '14px',
    color: '#495057',
    border: '1px solid #d4d4d4',
    padding: '5px 15px',
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 15px',
  },
  '& .rdt': {
    marginBottom: '15px',
    '& input': {
      backgroundImage: 'none',
      height: '40px',
      border: '1px solid #d4d4d4',
      padding: '10px 15px',
      '&::-webkit-input-placeholder': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      '&:-ms-input-placeholder ': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
};

export default customInputDialogStyle;
