import { put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import apis from '../../apis';
import actions from '../actions';
import { setCookie, getCookie } from '../../utils/cookie';
import { TIME } from '../../constants';
import { A_WEEK } from '../../configs';

function* loginSaga({ email, password }) {
  try {
    const { accessToken } = yield apis.auth.login(email, password);
    setCookie('accessToken', accessToken, TIME.A_WEEK);
    yield put(actions.auth.loginSuccess(accessToken));
  } catch (error) {
    yield put(actions.auth.loginFailure());
  }
}

function* loginWithFacebook(action) {
  try {
    const { payload } = action;
    const res = yield apis.auth.loginWithFacebook(payload);
    const { error, account, errorMsg } = res;

    if (error === 0) {
      const { accessToken, ...userInfo } = account;
      setCookie('accessToken', userInfo.token, TIME.A_WEEK);
      yield put(actions.auth.loginWithFacebookSuccess(userInfo));
    } else {
      yield put(actions.auth.loginWithFacebookFailure(errorMsg));
    }
  } catch (error) {
    yield put(actions.auth.loginWithFacebookFailure(error.message));
  }
}

function* verifyTokenSaga({ accessToken }) {
  try {
    const res = yield apis.auth.getAccountInfo();
    const { error, account } = res;

    if (error === 0) {
      yield put(actions.auth.verifyTokenSuccess(accessToken, account));
    } else throw new Error();
  } catch (error) {
    yield put(actions.auth.verifyTokenFailure());
  }
}

function* getUserInfoSaga() {
  try {
    const res = yield apis.auth.getAccountInfo();
    const { error, account } = res;

    if (error === 0) {
      yield put(actions.auth.getUserInfoSuccess(account));
    } else throw new Error();
  } catch (error) {
    yield put(actions.auth.getUserInfoFailure());
  }
}

function logoutSaga() {
  setCookie('accessToken', '');
}

function* updateMemberFirebaseTokenSaga({ userId, firebaseToken }) {
  try {
    let clientId = getCookie('clientId');
    if (!clientId) {
      clientId = uuidv4();
      setCookie('clientId', clientId, A_WEEK);
    }
    const data = yield apis.auth.updatePushToken({
      id: userId,
      token_firebase: firebaseToken,
    });
    if (!data.status) throw new Error();
    yield put(actions.auth.updateMemberFireBaseTokenSuccess(firebaseToken));
  } catch (error) {
    yield put(actions.auth.updateMemberFireBaseTokenFailure());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.auth.actionTypes.LOGIN, loginSaga),
    takeLatest(actions.auth.actionTypes.LOGIN_WITH_FACEBOOK, loginWithFacebook),
    takeEvery(actions.auth.actionTypes.VERIFY_TOKEN, verifyTokenSaga),
    takeLatest(actions.auth.actionTypes.GET_USER_INFO, getUserInfoSaga),
    takeLatest(actions.auth.actionTypes.LOGOUT, logoutSaga),
    takeLatest(
      actions.auth.actionTypes.UPDATE_MEMBER_FIREBASE_TOKEN,
      updateMemberFirebaseTokenSaga,
    ),
  ]);
}
