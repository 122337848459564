import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from '../constants';
import MainLayout from '../containers/MainLayout';

export default function PrivateRoute({ component: Component, ...rest }) {
  const accessToken = useSelector((state) => state.auth.accessToken);

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect to={ROUTES.LOGIN} />
        )
      }
    />
  );
}
