import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';

export default makeStyles((theme) => ({
  ...buttonStyle,
  backgroundTab: {
    '& .MuiTab-textColorInherit': {
      borderRadius: '0 !important',
      border: '1px solid #3157A7',
      fontWeight: '400',
    },
    '& .MuiTabs-indicator ': {
      height: '0px',
    },
    '& .Mui-selected': {
      border: '1px solid #3157A7',
      background: '#3157A7 !important',
      color: '#fff',
      fontWeight: '400',
    },
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
}));
