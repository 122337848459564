import {
  dangerColor,
  primaryColor,
} from 'assets/jss/material-dashboard-pro-react.js';

const paymentTransfer = () => ({
  formItem: {
    marginBottom: '20px',
  },
  formItemLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  formItemValue: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: primaryColor[0],
  },
  errorMessage: {
    color: dangerColor[0],
  },
  labelTextStep: {
    marginTop: '10px',
  },
});

export default paymentTransfer;
