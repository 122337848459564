/* eslint-disable react/display-name */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { ROUTES } from '../constants';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export default (routes) => {
  return (
    <Switch>
      {[...routes].map(
        ({
          path,
          exact = true,
          component: Component,
          isPrivate = true,
          ...rest
        }) => {
          if (!isPrivate) {
            return (
              <PublicRoute
                key={path}
                exact={exact}
                path={path}
                component={Component}
                {...rest}
              />
            );
          }
          return (
            <PrivateRoute
              key={path}
              exact={exact}
              path={path}
              component={Component}
              {...rest}
            />
          );
        },
      )}
      <Redirect to={ROUTES.HOME} />
    </Switch>
  );
};
