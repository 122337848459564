import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import styles from './index.style';
const useStyles = makeStyles(styles);

const PaymentVISA = ({ t }) => {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem style={{ marginLeft: '3%' }}>
        <FormLabel className={classes.labelLeftHorizontal}>
          1. {t('paymentVnPay')}
        </FormLabel>
        <FormLabel className={classes.labelLeftHorizontal}>
          2. {t('inforAndConfirm')}
        </FormLabel>
      </GridItem>
    </GridContainer>
  );
};

export default PaymentVISA;
