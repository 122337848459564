import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Button, Switch, TablePagination, Tooltip } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EditIcon from '@material-ui/icons/Edit';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import CardHeader from 'components/Card/CardHeader';
import Table from 'components/Table/Table';
import CardFooter from 'components/Card/CardFooter';
import TablePaginationActions from 'components/Table/TablePaginationActions';

import useStyles from './index.style';
import CreatePromotion from './CreatePromotion';
import UpdatePromotion from './UpdatePromotion';
import apis from '../../apis';

export default function ManagerPromtion() {
  const classes = useStyles();
  const { t } = useTranslation('managerPromotion');
  const { enqueueSnackbar } = useSnackbar();

  const [listPromtion, setListPromotion] = useState([]);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [promtionDetailData, setPromtionDetailData] = useState();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChange = async (e, id, item) => {
    try {
      const params = {
        id: id,
        active: e.target.checked === true ? 1 : 0,
        package_id: item.packageId,
        sale: item.sale,
        start_date: item.startDate,
        end_date: item.endDate,
      };
      const data = await apis.promotion.updatePromotion(params);
      const { error } = data;
      if (error === 0) {
        const newData = listPromtion.map((ele) => {
          if (ele.id === id) return { ...ele, active: params.active };
          return ele;
        });
        setListPromotion(newData);
        enqueueSnackbar(t('updateSuccess'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('updateFail'), { variant: 'error' });
      }
    } catch (error) { }
  };

  const onCloseDialogUpdate = () => {
    setVisibleUpdate(false);
  };

  const onCloseDialogCreate = () => {
    setVisibleCreate(false);
  };

  const onUpdatePromotion = (item) => {
    setVisibleUpdate(true);
    setPromtionDetailData(item);
  };

  const handleGetLisPromotion = async (params) => {
    try {
      const data = await apis.promotion.getListPromotion({
        page,
        limit: rowsPerPage,
        ...params,
      });
      if (data.error === 0) {
        setTotal(data.total);
        setListPromotion(data.list);
      }
    } catch (err) { }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    setListPromotion({ page: newPage, limit: rowsPerPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    setListPromotion({
      page: 0,
      limit: parseInt(event.target.value, 10),
    });
  };

  const handleAddPromotion = () => {
    handleGetLisPromotion();
    onCloseDialogCreate();
  };

  const handleUpdatePromotion = (updateProtion) => {
    const { id } = updateProtion;
    const updatedListPromotions = listPromtion.map((element) =>
      element.id === id ? updateProtion : element,
    );
    setListPromotion(updatedListPromotions);
    handleGetLisPromotion();
    onCloseDialogUpdate();
  };

  useEffect(() => {
    handleGetLisPromotion();
  }, []);

  const tableData = () => {
    if (listPromtion) {
      return listPromtion.map((item, index) => {
        return [
          index + 1,
          item.id,
          item.package ? item.package.title : '',
          item.sale,
          item.startDate,
          item.endDate,
          <Switch
            checked={item.active}
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={(e) => handleChange(e, item.id, item)}
            color="primary"
            classes={{
              track: classes.switch_track,
              switchBase: classes.switch_base,
              colorPrimary: classes.switch_primary,
            }}
          />,
          <div>
            <Tooltip title={t('edit')} placement="top">
              <Button
                className={classes.btnEdit}
                onClick={() => onUpdatePromotion(item)}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </div>,
        ];
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    tableData();
  }, [listPromtion]);

  return (
    <div className={classes.background}>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <MonetizationOnIcon />
          </CardIcon>
          <h3 className={classes.cardIconTitle}>{t('managerPromotion')}</h3>
          <div className={classes.boxCreate}>
            <Button
              onClick={() => setVisibleCreate(true)}
              className={classes.btnCreate}
            >
              {t('addNew')}
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table
            tableHead={[
              'STT',
              'ID',
              t('package'),
              t('value'),
              t('startDate'),
              t('endDate'),
              t('status'),
              t('action'),
            ]}
            tableData={tableData()}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            customHeadCellClasses={[
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
            ]}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />
        </CardBody>
        <CardFooter>
          <TablePagination
            className={classes.tablePagination}
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </CardFooter>
      </Card>

      {visibleCreate && (
        <CreatePromotion
          visible={visibleCreate}
          onCloseDialog={onCloseDialogCreate}
          addPromotion={handleAddPromotion}
        />
      )}
      {visibleUpdate && (
        <UpdatePromotion
          visible={visibleUpdate}
          onCloseDialog={onCloseDialogUpdate}
          promotionDetail={promtionDetailData}
          updateProtion={handleUpdatePromotion}
        />
      )}
    </div>
  );
}
