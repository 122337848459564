import {
  primaryColor,
  grayColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.js';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

const asidebarStyle = (theme) => ({
  ...sweetAlertStyle,
  ...modalStyle(theme),
  container: {
    backgroundColor: '#F5F5F5',
    '& .react-swipeable-view-container': {
      '& div[aria-hidden="true"]': {
        height: '10px',
      },
      '& div[aria-hidden="false"]': {
        paddingRight: '1px',
      },
    },
  },
  headerStep: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '10px',
    marginTop: '10px',
    color: '#3157A7',
  },
  avatarImg: {
    width: '100%',
    verticalAlign: 'middle',
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
  },
  avatarShapeImg: {
    width: '100%',
    verticalAlign: 'middle',
    marginTop: '5px',
    padding: '2px',
    '&:hover': {
      border: `1px solid ${primaryColor[0]}`,
    },
  },
  carouselImg: {
    width: '100%',
    objectFit: 'cover',
    backgroundSize: 'cover',
    padding: '5px',
    borderRadius: '10px',
    cursor: 'pointer',
    '@media (max-width: 990px)': {
      padding: '2px',
    },
  },
  sliderItem: {
    borderRadius: '5px',
    padding: '5px',
  },
  itemSelected: {
    boxSizing: 'border-box',
    border: `1px solid ${whiteColor}`,
    boxShadow: '0px 0px 8px #81BCFF',
  },
  nameMC: {
    marginTop: '10px',
    textAlign: 'center',
  },
  iconCheck: {
    maxWidth: '8px',
  },
  flexContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  displayNone: {
    display: 'none !important',
  },
  fixed: {
    overflow: 'visible !important',
  },
  horizontalDisplay: {
    display: 'block',
  },
  root: {
    marginTop: '0',
    paddingLeft: '0',
    marginBottom: '0',
    marginRight: '-30px',
    overflow: 'visible !important',
    backgroundColor: '#D7D7D7',
    height: 'calc(100vh - 110px)',
    borderRadius: '20px 0px 0px 20px',
  },
  pills: {
    float: 'left',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    color: grayColor[6],
    height: 'auto',
    opacity: '1',
    fontSize: '12px',
    maxWidth: '100%',
    minWidth: '100px',
    minHeight: '48px',
    textAlign: 'center',
    transition: 'all .3s',
    fontWeight: '500',
    lineHeight: '24px',
    textTransform: 'uppercase',
    letterSpacing: 'initial',
  },
  pillsSelected: {
    backgroundColor: '#E8E8E8',
    color: '#4A6AAE',
  },
  pillsBorderTopLeft: {
    borderTopLeftRadius: '20px',
  },
  tabIcon: {
    width: '30px',
    height: '30px',
    display: 'block',
    margin: '15px 0 !important',
  },
  horizontalPills: {
    width: '100%',
    float: 'none !important',
    padding: '5px 7px !important',
    minWidth: 'auto !important',
  },
  bage: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#81BCFF',
    },
  },
  closthingItem: {
    padding: '15px !important',
    '@media (max-width: 1180px)': {
      padding: '7px !important',
    },
  },
  tabContent: {
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'scroll',
  },
  listTemplates: {
    margin: '10px 0px',
  },
  templateContainer: {
    padding: '20px 0px',
  },
  templateImg: {
    maxWidth: 'calc(100% - 10px)',
    borderRadius: '10px',
    padding: '5px',
    margin: '0px 5px',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > img': {
      maxWidth: '203px',
      marginTop: '10px',
      width: '100%',
    },
    '& > .MuiTypography-root': {
      marginTop: '12px',
      marginBottom: '20px',
    },
  },
  resetButton: {
    marginTop: '6px',
    marginBottom: '14px',
  },
  buttonWidthPadding: {
    width: '100%',
    paddingLeft: '40px',
    paddingRight: '40px',
    '@media (max-width: 1280px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  buttonMaxWidth: {
    width: '100%',
  },
  primaryButton: {
    color: whiteColor,
    width: '100%',
    padding: '15px',
    '&,&:hover,&:focus': {
      backgroundColor: primaryColor[0],
    },
    '&.MuiButton-root.Mui-disabled': {
      color: whiteColor,
    },
  },
  greyButton: {
    color: whiteColor,
    width: '100%',
    padding: '15px',
    margin: '15px 0px',
    '&,&:hover,&:focus': {
      backgroundColor: '#636363',
    },
  },
  customFormControlClasses: {
    paddingTop: '0px !important',
    marginBottom: '34px !important',
    '&:first-child': {
      marginTop: '53px',
    },
  },
  draftButton: {
    padding: '28px 0px',
    margin: '35px 0px 20px',
  },
  textSuccess: {
    color: '#53A653',
    textAlign: 'center',
    margin: '5px',
    transition: 'width 0.6s ease',
  },
  activeText: {
    color: '#3157A7',
  },
  pllsSelectedClasses: {
    color: '#3157A7',
  },
  imageLoading: {
    width: '18px',
    marginRight: '15px',
  },
  zoomIn: {
    position: 'relative',
    marginTop: '10px',
    '&:hover': {
      '&:before': {
        display: 'block',
      },
      '& svg': {
        display: 'block',
      },
    },
    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: 'rgb(0 0 0 / 60%)',
      zIndex: 1,
      display: 'none',
    },
    '& img': {
      width: '100%',
    },
    '& svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 9,
      transform: 'translate(-50%, -50%)',
      fontSize: '40px',
      display: 'none',
      cursor: 'pointer',
      color: '#fff',
    },
  },
  dialogPreview: {
    '& $modalHeader': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      backgroundColor: primaryColor[0],
      color: '#fff',
      alignItems: 'center',
      padding: '5px 15px',
      '& h3': {
        fontSize: '20px',
        margin: 0,
      },
    },
    '& $modalCloseButton': {
      marginTop: 0,
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
    '& img': {
      width: '100%',
      maxWidth: '100%',
    },
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: '50%',
    },
    fontSize: '16px',
  },
  typeTemplate: {
    padding: 0,
    margin: 0,
    '& li': {
      display: 'inline-flex',
      padding: '5px 15px',
      margin: '5px 2px',
      color: '#3157A7',
      cursor: 'pointer',
      fontSize: '15px',
      fontWeight: '400',
      border: '1px solid #D0D0D0',
      borderRadius: '20px',
    },
  },
  typeActive: {
    fontWeight: '500 !important',
    border: '1px solid #3157A7 !important',
    backgroundColor: '#3157A7 !important',
    color: '#fff !important',
  },
});

export default asidebarStyle;
