import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import queryString from 'query-string';

import { Button, CircularProgress, Tooltip } from '@material-ui/core';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import PictureUpload from 'components/CustomUpload/PictureUpload';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import useStyles from './index.style';
import IMAGES from '../../../constants/images';
import apis from '../../../apis';
import { ROUTES } from '../../../constants';
import actions from '../../../redux/actions';
import ReportError from '../../../containers/ReportError';
import CustomOutlinedInput from 'components/CustomInput/CustomOutlinedInput';


const SidebarPerson = ({ showCreateTemplateError }) => {
  const classes = useStyles();
  const { t } = useTranslation('userPage');

  const history = useHistory();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);
  const { packages } = useSelector((state) => state.payment);

  const {
    id,
    username,
    expireKind,
    packageId,
    totalVideoAvailable,
    totalVideoCurrentUse,
    email,
    phone,
    token,
  } = userInfo;
  const [alert, setAlert] = useState(null);
  const [visiableCopy, setVisiableCopy] = useState(false);

  const hideAlert = () => {
    setAlert(null);
  };

  const subscribedPkg = useMemo(() => {
    if (packages.length === 0) return null;
    return packages.find((pkg) => pkg.id === packageId);
  }, [packageId, packages]);

  const [usedTemplates, setUsedTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchListUsedTemplates = async () => {
    setLoading(true);
    const res = await apis.resource.getListUsedTemplates();
    const { error, list } = res;
    if (error === 0) {
      setUsedTemplates(list);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListUsedTemplates();
  }, []);

  // const fetchPrevious = () => {
  //   fetchListUsedTemplates();
  // };

  // const fetchNext = () => {
  //   fetchListUsedTemplates();
  // };

  const onConfirmUpdateEmail = async (value) => {
    hideAlert();
    try {
      const res = await apis.auth.updateAccount({ email: value.trim() });
      const { error } = res;
      if (error === 0) {
        dispatch(actions.auth.getUserInfo());
      }
    } catch (_) { }
  };

  const handleUpdateEmail = () => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('updateEmail')}
        onConfirm={(value) => {
          onConfirmUpdateEmail(value);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.info}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        cancelBtnText={t('cancel')}
      />,
    );
  };

  const onConfirmUpdatePhone = async (value) => {
    hideAlert();
    try {
      const res = await apis.auth.updateAccount({ phone: value.trim() });
      const { error } = res;
      if (error === 0) {
        dispatch(actions.auth.getUserInfo());
      }
    } catch (_) { }
  };

  const handleUpdatePhone = () => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('updatePhone')}
        onConfirm={(value) => {
          onConfirmUpdatePhone(value);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.info}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        cancelBtnText={t('cancel')}
      />,
    );
  };

  const onCreateVideo = () => {
    history.push(ROUTES.GREEN_SCREEN);
  };

  const onCreateVideoTemplate = (params) => {
    if (!packageId) {
      showCreateTemplateError();
      return;
    }

    const search = params ? queryString.stringify(params) : '';
    history.push({
      pathname: ROUTES.CREATE_VIDEO,
      search,
    });
  };

  const handleGotoPricing = () => {
    history.push(ROUTES.PRICING);
  };

  const handleCopyAccessToken = () => {
    setVisiableCopy(true);
    navigator.clipboard.writeText(token);
    setTimeout(() => {
      setVisiableCopy(false);
    }, 1000);
  };

  return (
    <div className={classes.sidebarPerson}>
      <div className={classes.stickyContainer}>
        <img
          className={classes.backgroundInfo}
          src={IMAGES.bgInfoPerson}
          alt="bg-info-user"
        />
        <div className={classes.inforPerson}>
          <div className={classes.infoUser}>
            <PictureUpload />
            <div className={classes.infoContainer}>
              <div className={classes.username}>
                <h3>{username}</h3>
              </div>
              <h4 className={classes.textH4}>ID: {id}</h4>
              <p className={classes.textP}>
                {subscribedPkg?.title || 'N/A'}
                <Button
                  className={`${classes.btnBorderRadius} ${classes.btnUpgrade}`}
                  onClick={handleGotoPricing}
                >
                  {packageId ? t('buyMore') : t('upgrade')}
                </Button>
              </p>
            </div>
          </div>
          <div className={classes.infoVideo}>
            <p text>
              <img src={IMAGES.iconEmail} alt="icon-email" />
              <span className={classes.infoValue}>{email || 'N/A'}</span>
              <Button
                className={classes.iconEditor}
                onClick={handleUpdateEmail}
              >
                <img src={IMAGES.iconEditor} />
              </Button>
            </p>
            <p>
              <img src={IMAGES.iconTel} alt="icon-tel" />
              <span className={classes.infoValue}>{phone || 'N/A'}</span>
              <Button
                className={classes.iconEditor}
                onClick={handleUpdatePhone}
              >
                <img src={IMAGES.iconEditor} />
              </Button>
            </p>
            <p>
              <img src={IMAGES.iconVideo} alt="icon-video" />
              <span>
                {t('videosAvailable')}: {totalVideoAvailable}
              </span>
            </p>
            <p>
              <img src={IMAGES.iconTimeAccount} alt="icon-video" />
              <span>
                {t('accountTerm')}: {expireKind || 'N/A'}
              </span>
            </p>
            <p>
              <img src={IMAGES.iconUsedVideo} alt="icon-video" />
              <span>
                {t('videoUsed')}: {totalVideoCurrentUse}
              </span>
            </p>
          </div>
          {/*<div className={classes.accessToken}>
            <VpnKeyIcon className={classes.iconKey}/>
            <CustomOutlinedInput formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.inputToken,
                disabled: classes.disabled,
                value: token,
                placeholder: 'ID',
              }} ></CustomOutlinedInput>
              <Tooltip  title={visiableCopy ? 'Copied' : ''} placement="top">
                <Button className={classes.btnCopy} onClick={()=> handleCopyAccessToken() }>
                  <FileCopyIcon />
                </Button>
              </Tooltip>
          </div>*/}
          {/*<div>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {!loading && usedTemplates.length !== 0 && (
              <GridContainer className={classes.templateContainer}>
                {usedTemplates.map((item, index) => {
                  const { image, template, mc } = item;

                  return (
                    <GridItem className={classes.template} key={index} sm={6}>
                      <Button
                        onClick={() =>
                          onCreateVideoTemplate({
                            templateId: template.id,
                            mcId: mc.id,
                          })
                        }
                      >
                        <img src={image} alt="" />
                      </Button>
                    </GridItem>
                  );
                })}
              </GridContainer>
            )}
            {/* {!loading && usedTemplates.length && (
              <div className={classes.pagination}>
                <Button
                  className={classes.paginationButton}
                  onClick={fetchPrevious}
                >
                  <img src={IMAGES.prevIcon} alt="prev-icon" />
                </Button>
                <span className={classes.currentPage}>1/3</span>
                <Button
                  className={classes.paginationButton}
                  onClick={fetchNext}
                >
                  <img src={IMAGES.nextIcon} alt="next-icon" />
                </Button>
              </div>
            )} }
          </div>*/}
        </div>
        <div className={classes.btnCreated}>
          <div className={classes.div50}>
            <div className={classes.btnContainer}>
              <Button
                className={classes.btnCreateVideo}
                onClick={onCreateVideo}
              >
                <img src={IMAGES.womanIcon} alt="mc-icon" />
                <span>{t('createMCGreen')}</span>
              </Button>
            </div>
          </div>
          <div className={classes.div50}>
            <div className={classes.btnContainer}>
              <Button
                className={classes.btnCreateVideo}
                onClick={() => onCreateVideoTemplate()}
              >
                <img src={IMAGES.onlineLearningIcon} alt="video-icon" />
                <span>{t('createVideoScript')}</span>
              </Button>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}><br/>
        <a href="https://hocvien.aiclip.ai/" target="_blank">
          <img style={{ width: "90%" }} src="/images/BUTTON-HOCVIEN.png" alt="video-icon" /></a>
        </div>
        {/*<ReportError />*/}
      </div>
      {alert}
    </div>
  );
};
export default SidebarPerson;

SidebarPerson.propTypes = {
  showCreateTemplateError: PropTypes.func.isRequired,
};
