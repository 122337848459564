import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'components/Table/Table';

import useStyles from './index.style';
import apis from 'apis';

const PackageTrailGiven = ({ isActive }) => {
  const { t } = useTranslation('cmsAgent');
  const classes = useStyles();

  const [listData, setListData] = useState(null);

  const getListData = async (params) => {
    try {
      const data = await apis.agent.listGift(params);
      const { error, rows } = data;
      if (error === 0) {
        setListData(rows);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (!isActive || listData) return;
    getListData();
  }, [isActive, listData]);

  const tableData = () => {
    if (listData) {
      return listData.map((item, index) => {
        return [
          index + 1,
          item.account.id,
          item.account.username,
          item.account.phone,
          item.account.email,
        ];
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    tableData();
  }, [listData]);

  return (
    <div className={classes.background}>
      <Table
        tableHead={[
          {
            minWidth: '50',
            title: 'STT',
          },
          {
            minWidth: '50',
            title: 'ID',
          },
          {
            minWidth: '150',
            title: t('fullName'),
          },
          {
            minWidth: '150',
            title: t('phoneNumber'),
          },
          {
            minWidth: '200',
            title: 'Email',
          },
        ]}
        tableData={tableData()}
        customCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4]}
        customHeadCellClasses={[
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4]}
      />
    </div>
  );
};

export default PackageTrailGiven;
