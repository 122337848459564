import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import Table from 'components/Table/Table';
import TablePaginationActions from 'components/Table/TablePaginationActions';

import { formatNumber } from 'utils/number';
import { PAYMENT_METHOD } from '../../constants';

import apis from '../../apis';

import styles from './index.style';
const useStyles = makeStyles(styles);

const TransactionHistory = () => {
  const classes = useStyles();
  const { t } = useTranslation(['transactionHistory']);

  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // alert
  const [alert, setAlert] = useState(null);

  const handleShowAlert = (message) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: 'block', marginTop: '-100px' }}
        title={message}
        confirmBtnText={t('confirmBtnText')}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        {t('notFoundPackage')}
      </SweetAlert>,
    );
  };

  let mappingStatusOrder = {
    0: 'Khởi tạo',
    1: 'Thành công',
    2: 'Thất bại',
  };

  const convertMethodPayment = (type) => {
    switch (type.toLowerCase()) {
      case PAYMENT_METHOD.ATM:
        return 'Thẻ ATM nội địa';
      case PAYMENT_METHOD.VISA:
        return 'Thẻ thanh toán nội địa';
      case PAYMENT_METHOD.PAYPAL:
        return 'PayPal';
      case PAYMENT_METHOD.TRANSFER:
        return 'Chuyển khoản ngân hàng';
    }
  };

  const handleGetListOrder = async (params) => {
    try {
      setLoading(true);
      const data = await apis.payment.getListOrders({
        page,
        limit: rowsPerPage,
        ...params,
      });
      if (data.error === 0) {
        const { limit: limitData, page: currentPage } = {
          page,
          limit: rowsPerPage,
          ...params,
        };
        if (data.status) {
          mappingStatusOrder = data.status;
        }
        setTotal(data.total);
        setOrderData(() =>
          data.list.map((item, index) => [
            limitData * currentPage + index + 1,
            item.id,
            item.package ? item.package.title : '',
            mappingStatusOrder[item.status],
            item.createdAt,
            item.expireKind,
            convertMethodPayment(item.bankType),
            item.package ? formatNumber(item.package.price) : '',
            formatNumber(item.price),
          ]),
        );
      }
    } catch (err) {
      handleShowAlert(err.message);
    }

    setLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    handleGetListOrder({ status: 1, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    handleGetListOrder({ status: 1, limit: parseInt(event.target.value, 10) });
  };

  useEffect(() => {
    handleGetListOrder({ status: 1, page: 0 });
  }, []);

  return (
    <GridContainer className={classes.container}>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>{t('title')}</h3>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  '#',
                  'Mã giao dịch',
                  'Gói dịch vụ',
                  'Trạng thái',
                  'Thời gian',
                  'Hạn sử dụng',
                  'Hình thức thanh toán',
                  'Mệnh giá',
                  'Thành tiền',
                ]}
                tableData={orderData}
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
              />
            </CardBody>
            <CardFooter className={classes.cardFooter}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </CardFooter>
          </Card>
        </GridItem>
      )}
      {alert}
    </GridContainer>
  );
};

export default TransactionHistory;
