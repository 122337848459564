import api from './api';

export async function login(email, password) {
  const loginInfo = await api({
    method: 'POST',
    url: '/auths/login',
    data: { email, password },
  });
  return loginInfo;
}

export async function loginWithFacebook(token) {
  const data = await api({
    method: 'GET',
    url: '/account/login',
    params: { token },
  });
  return data;
}

export async function getAccountInfo() {
  const data = await api({
    method: 'GET',
    url: '/account/info',
  });
  return data;
}

export async function updateAccount(updateData) {
  const data = await api({
    method: 'POST',
    url: '/account/update',
    data: updateData,
  });
  return data;
}

export async function contactUsNow(params) {
  const data = await api({
    method: 'GET',
    url: '/account/contact_us_now',
    params,
  });
  return data;
}

export async function updatePushToken(updateData) {
  const data = await api({
    method: 'POST',
    url: '/account/token_firebase',
    data: updateData,
  });
  return data;
}
