import React from 'react';

import ScrollToTop from '../../components/ScrollToTop';
import Content from './Content';
import Header from './Header';
import { StyledLayout } from './index.style';

const MainLayout = ({ children }) => {
  return (
    <ScrollToTop>
      <StyledLayout>
        <Header />
        <Content>{children}</Content>
      </StyledLayout>
    </ScrollToTop>
  );
};

export default MainLayout;
