import { IconButton } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';

import store from '../../redux/store';
import AppRouter from '../../router';

const notistackRef = React.createRef();

const App = () => {
  const onClickDismiss = (key) => () => {
    if (notistackRef.current) notistackRef.current.closeSnackbar(key);
  };

  return (
    <Provider store={store()}>
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <ClearIcon style={{ color: 'whitesmoke' }} />
          </IconButton>
        )}
      >
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  );
};

export default App;
