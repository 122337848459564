import * as auth from './auth';
import * as payment from './payment';
import * as video from './video';
import * as resource from './resource';
import * as voice from './voice';
import * as order from './order';
import * as account from './account';
import * as promotion from './promotion';
import * as agent from './agent';

export default {
  auth,
  payment,
  video,
  resource,
  voice,
  order,
  promotion,
  account,
  agent,
};
