import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import customInputDialogStyle from 'assets/jss/material-dashboard-pro-react/components/customInputDialogStyle';

export default makeStyles((theme) => ({
  ...buttonStyle,
  ...modalStyle(theme),
  dialogPromotion: {
    ...customInputDialogStyle,
  },
  switch_track: {
    backgroundColor: '#6a6868',
  },
  switch_base: {
    color: '#999',
    '&.Mui-disabled': {
      color: '#e886a9',
    },
    '&.Mui-checked': {
      color: '#95cc97',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4CAF50',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#4CAF50',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4CAF50',
    },
  },
  fullWidth: {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid #c4c4c4',
    height: 'auto !important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '14px',
    color: '#495057',
  },
  selectLabel: {
    fontSize: '14px',
    paddingBottom: '10px',
  },
}));
