/* eslint-disable no-new */
import firebase from 'firebase/app';
import 'firebase/messaging';
import axios from 'axios';

export const initializeFirebase = () => {
  axios
    .get(`${process.env.PUBLIC_URL}/firebase.json`)
    .then((response) => {
      const FIREBASE_CONFIG = response.data;
      firebase.initializeApp(FIREBASE_CONFIG);
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: payload.notification.icon,
        };

        if (!('Notification' in window)) {
          console.log('This browser does not support system notifications.');
        } else if (Notification.permission === 'granted') {
          new Notification(notificationTitle, notificationOptions);
        }
      });
    })
    .catch((error) =>
      console.error('Cannot fetch firebase credentials', error),
    );
};

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const currentToken = await messaging.getToken({
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      return currentToken;
    }
    console.log('Notification permission denied');
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
  return null;
};
