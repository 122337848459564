import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

export default makeStyles((theme) => ({
  ...modalStyle(theme),
  ...buttonStyle,
  Footer: {
    overflow: 'hidden',
    height: 'max-content',
    display: 'flex',
    position: 'relative',
    bottom: '0px',
    background: '#fff',
    '@media (max-width: 1400px)': {
      display: 'block',
    },
  },
  footerA: {
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  },
  firstColumn: {
    background: '#3157A7',
    width: '75%',
    height: 'auto',
    borderTopRightRadius: '150px',
    padding: '2% 4% 0 calc((100vw - 1170px)/2)',
    '@media (max-width: 1400px)': {
      width: '100%',
      height: 'max-content',
      padding: '2% 9% 1% 9%',
    },
    '@media (max-width: 425px)': {
      padding: '20px',
    },
    '@media (min-width: 768px)': {
      padding: '5% 5% 0 calc((100vw - 750px)/2)',
    },
    '@media (min-width: 992px)': {
      padding: '5% 5% 0 calc((100vw - 970px)/2)',
    },
    '@media (min-width: 1170px)': {
      padding: '2% 4% 0 calc((100vw - 1024px)/2)',
    },
    '@media (min-width: 1200px)': {
      padding: '2% 4% 0 calc((100vw - 1170px)/2)',
    },
    '@media (min-width: 2560px)': {
      height: 'auto',
      padding: '2% 4% 0 calc((100vw - 1900px)/2)',
    },
  },
  secondColumn: {
    width: '25%',
    // height: '875px',
    background: '#3157A7',
    '@media (min-width: 1920px)': {
      // height: '934px',
      width: '25%',
      background: '#3157A7',
    },
    '@media (min-width: 2560px)': {
      // height: '1006px',
    },
    '@media (max-width: 1400px)': {
      width: '100%',
      height: 'max-content',
      background: '#fff',
    },
  },
  decorRadius: {
    height: '100%',
    background: '#fff',
    borderBottomLeftRadius: '180px',
    transform: 'translate(0px, -55px)',
    '@media (min-width: 1920px)': {
      // height: '937px',
    },
    '@media (min-width: 2560px)': {
      // height: '1010px',
    },
    '@media (max-width: 1400px)': {
      display: 'none',
    },
  },
  imgDecor: {
    position: 'absolute',
    bottom: '-5%',
    right: '26%',
    width: '45%',
    '@media (min-width: 1920px)': {
      width: '777px',
    },
    '@media (max-width: 1400px)': {
      display: 'block',
      transform: 'translate(170px,-840px)',
      mixBlendMode: 'color',
    },
  },
  footerUl: {
    listStyleType: 'none',
    color: '#fff',
  },
  titleFooter: {
    '@media (min-width: 768px)': {
      width: '750px',
    },
    '@media (min-width: 992px)': {
      width: '970px',
    },
    '@media (min-width: 1170px)': {
      width: '1024px',
    },
    '@media (min-width: 1200px)': {
      width: '1170px',
    },
    '@media (min-width: 2560px)': {
      width: '1900px',
    },

    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  textTitleFooter: {
    width: '100%',
    display: 'flex',
    '@media (max-width: 425px)': {
      width: '100%',
      alignItems: 'end',
    },
  },
  titleH1Footer: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '36px',
    lineHeight: '46px',
    width: '40%',
    color: '#FFFFFF',
    '@media (max-width: 425px)': {
      width: '70%',
      marginTop: '25px',
    },
    '@media (max-width: 768px)': {
      width: '50%',
    },
  },
  titleFooterP: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#81BCFF',
    '@media (max-width: 425px)': {
      width: '100%',
      whiteSpace: 'normal',
      margin: '15px 0',
    },
  },
  titleFooterImg: {
    border: 'none',
    width: '200px',
    marginLeft: '15%',
    borderRadius: '180px',
    '@media (max-width: 768px)': {
      width: '150px',
      height: '150px',
      margin: '40px',
    },
    '@media (min-width: 2560px)': {
      marginLeft: '7%',
    },
    '@media (max-width: 425px)': {
      width: '85px',
      height: '85px',
      margin: '40px',
    },
  },
  formContact: {
    display: 'flex',
    marginTop: '2%',
    '@media (max-width: 768px)': {
      display: 'grid',
    },
  },
  formContactInput: {
    background: '#3157A7',
    border: '1px solid #81BCFF',
    borderRadius: '10px',
    width: '70%',
    color: '#CAE3FF',
    fontSize: '15px',
    padding: '0 25px',
    '&::placeholder': {
      color: '#CAE3FF',
      padding: '0',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      height: '35px',
      marginBottom: '30px',
    },
  },
  formContactButton: {
    marginLeft: '3%',
    width: '30%',
    background: '#2091F9',
    borderRadius: '10px',
    border: '1px solid #2091F9',
    height: '48px',
    color: '#fff',
    '& img': {
      width: '20px',
      marginLeft: '-30px',
      marginRight: '10px',
    },
    '@media (max-width: 768px)': {
      margin: '0px',
    },
    '@media (max-width: 425px)': {
      width: '100%',
      margin: '0',
    },
  },
  btnContact: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '26px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  decor: {
    margin: '50px 0',
  },
  decorHr: {
    color: '#fff',
    border: '1px solid #6B99CC',
    borderRadius: '2px',
  },
  listSupport: {
    display: 'flex',
    listStyleType: 'none',
    padding: '0',
    justifyContent: 'space-between',
    '@media (max-width: 1400px)': {
      padding: '0',
      justifyContent: 'space-between',
    },
    '@media (max-width: 768px)': {
      display: 'block',
      padding: '0',
    },
  },
  listSupportLi: {
    display: 'flex',
    listStyleType: 'none',
    position: 'relative',
    zIndex: '1',
    padding: '0',
    '&:first-child': {
      maxWidth: '70%',
      '@media (max-width: 1450px) and (min-width: 768px)': {
        padding: '0',
        justifyContent: 'space-between',
        maxWidth: '67%',
      },
    },
  },
  listSupportLiChild: {
    display: 'flex',
    listStyleType: 'none',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '26px',
    padding: '15px 0',
  },
  listSupportLiA: {
    color: '#81BCFF',
    display: 'inline-flex',
    '@media (max-width: 425px)': {
      whiteSpace: 'nowrap',
    },
    '&:hover, &:focus': {
      color: '#fff',
    },
  },

  listContactLi: {
    padding: '20px 0',
    listStyleType: 'none',
  },
  listIntroduceLi: {
    padding: '17px 0',
    listStyleType: 'none',
  },
  listFollowLi: {
    padding: '17px 0',
    listStyleType: 'none',
  },
  end: {
    fontFamily: 'SVN-Gilroy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
    color: '#81BCFF',
    '@media (max-width: 1400px)': {
      textAlign: 'center',
    },
  },
  footerSecondColImgFirst: {
    transform: 'translate(50%,900%)',
    position: 'relative',
    zIndex: '1',
    '@media (max-width: 1400px)': {
      transform: 'none',
      margin: 'auto',
      display: 'block',
      padding: '15px 0',
    },
  },
  footerSecondColImgLast: {
    position: 'relative',
    left: '-2px',
    bottom: '-455px',
    marginLeft: '0px',
  },
  footerH4: {
    color: '#fff',
    fontWeight: 'bold',
  },
  listContact: {
    padding: '0',
    '@media (max-width: 1400px)': {
      padding: '0',
    },
  },
  listIntroduce: {
    padding: '0',
    margin: '0',
    '@media (max-width: 1400px)': {
      padding: '0',
      margin: '0',
    },
  },
  listFollow: {
    padding: 0,
    '@media (max-width: 1400px)': {
      padding: '0',
    },
  },
  hidden: {
    display: 'block',
    '@media (min-width: 768px) and (max-width: 1400px)': {
      display: 'none',
    },
  },
  boxAnnounced: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '15px',
    '& img': {
      width: '100%',
      zIndex: 9,
    },
    '& a': {
      width: '20%',
      zIndex: 9,
    },
  },
}));

export const I = styled.i`
  color: #81bcff;
  padding: 0 10px 0 0;
`;
