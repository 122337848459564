import api from './api';

export async function getListPromotion(params) {
  const data = await api({
    method: 'GET',
    url: '/promotions/list',
    params,
  });
  return data;
}

export async function createPromotion(params) {
  const data = await api({
    method: 'POST',
    url: '/promotions/create',
    data: params,
  });
  return data;
}

export async function updatePromotion(params) {
  const data = await api({
    method: 'POST',
    url: '/promotions/update',
    data: params,
  });
  return data;
}
