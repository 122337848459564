import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  socialTitle: {
    color: '#808080',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  socialButtons: {
    display: 'flex',
    alignItems: 'start',
    '& .MuiButtonBase-root': {
      padding: '0px',
      minWidth: 'unset',
      [theme.breakpoints.down('xs')]: {
        '& img': {
          height: '40px',
        },
      },
    },
  },
  marginRight: {
    marginRight: '33px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '11px',
    },
  },
}));
