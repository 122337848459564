import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { omitIsNil } from 'utils/omit';
import apis from 'apis';

import PromotionForm from '../PromotionForm';

const CreatePromotion = ({ visible, onCloseDialog, addPromotion }) => {
  const { t } = useTranslation('managerPromotion');
  const { enqueueSnackbar } = useSnackbar();

  const onCreatePromotion = async ({
    sale,
    packageId,
    startDate,
    endDate,
    active,
  }) => {
    const promotionData = omitIsNil(
      {
        package_id: packageId,
        sale: sale.trim(),
        start_date: startDate,
        end_date: endDate,
        active: active,
      },
      { deep: false },
    );

    try {
      const data = await apis.promotion.createPromotion(promotionData);
      const { error, insert } = data;
      if (error === 0) {
        addPromotion(insert);
        enqueueSnackbar(t('addNewSuccess'), {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      } else {
        enqueueSnackbar(t('addNewFail'), {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    } catch (error) { }
  };

  return (
    <PromotionForm
      visible={visible}
      onCloseDialog={onCloseDialog}
      title={t('addNew')}
      onConfirm={onCreatePromotion}
    />
  );
};

export default CreatePromotion;
