import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  sliderContainer: {
    position: 'relative',
    padding: '0px 32px',
  },
  sliderButton: {
    padding: '0px',
    position: 'absolute',
    top: '50%',
    minWidth: 'unset',
    transform: 'translate(0, -50%)',
  },
  sliderNext: {
    right: '-25px',
  },
  sliderPrev: {
    left: '-25px',
  },
}));
