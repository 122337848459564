import axios from 'axios';
import camelCase from 'camelcase-keys';

import { API_DOMAIN } from 'configs';
import { getCookie } from '../utils/cookie';

const axiosClient = axios.create({
  baseURL: `${API_DOMAIN}/api/public`,
  responseType: 'json',
  timeout: 30 * 1000,
  transformResponse: [
    (data) => {
      if (typeof data === 'object') {
        const { voice_info } = data;
        if (voice_info) return data;
      }
      return camelCase(data, { deep: true });
    },
  ],
});

axiosClient.interceptors.request.use(async (config) => {
  const accessToken = getCookie('accessToken');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = accessToken;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Handle error
    console.error(error);
    if (error.response && error.response.data) {
      return error.response.data;
    }
  },
);

export default axiosClient;
