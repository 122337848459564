import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

export default makeStyles((theme) => ({
  ...buttonStyle,
  btnAccept: {
    border: '1px solid #3157A7',
    color: '#3157A7',
    padding: '5px 15px',
    marginRight: '10px',
    '&:hover': {
      background: '#3157A7',
      color: '#fff',
    },
  },
  btnCancel: {
    border: '1px solid #dc3545',
    color: '#dc3545',
    padding: '5px 15px',
    '&:hover': {
      background: '#dc3545',
      color: '#fff',
    },
  },
  tablePagination: {
    width: '100%',
    float: 'right',
  },
}));
