import api from './api';

export async function getListTemplates(params) {
  const data = await api({
    method: 'GET',
    url: '/resources/list_templates',
    params: params,
  });
  return data;
}

export async function getListUsedTemplates() {
  const data = await api({
    method: 'GET',
    url: '/resources/me/templates',
  });
  return data;
}

export async function getMC(params) {
  const result = await api({
    method: 'GET',
    url: '/resources/mc',
    params,
  });
  return result;
}

export async function getMCTemplate(params) {
  const result = await api({
    method: 'GET',
    url: '/resources/mc_by_template',
    params,
  });
  return result;
}

export async function getListCategorys(params) {
  const data = await api({
    method: 'GET',
    url: '/category/list',
    params,
  });
  return data;
}

export async function uploadFile({ formData, onUploadProgress }) {
  const data = await api({
    method: 'POST',
    url: '/post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: onUploadProgress,
  });
  return data;
}

export async function splitSentence(inputText) {
  const data = await api({
    method: 'POST',
    url: '/home/split',
    data: inputText,
  });
  return data;
}

export async function reportError(dataReport) {
  const data = await api({
    method: 'POST',
    url: '/home/report_error',
    data: dataReport,
  });
  return data;
}
