import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import appRoutes from './routes';
import { routes } from './config';
import actions from '../redux/actions';
import { getCookie } from '../utils/cookie';

const AppRouter = () => {
  const { verifying } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    const tokenFromCookie = getCookie('accessToken');
    if (tokenFromCookie) {
      dispatch(actions.auth.verifyToken(tokenFromCookie));
      dispatch(actions.payment.getListPackages());
    }
    setIsFirstTime(false);
  }, []);

  if (isFirstTime || verifying) return <CircularProgress />;

  return (
    <Switch>
      <Route>{appRoutes(routes)}</Route>
    </Switch>
  );
};

export default AppRouter;
