import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import styles from './index.style';
const useStyles = makeStyles(styles);

const PaymentTransfer = ({ bankInfo, onChangeBankSelect, listBank=[], messageError, t }) => {
  const classes = useStyles();

  return (
    <GridItem>
      <Typography variant="body1">{t('selectBankTransfer')}</Typography>
      <Typography variant="body2" className={classes.labelTextStep}>
          1. {t('selectBankTransfer')}
      </Typography>
      <Select
        fullWidth
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        onChange={onChangeBankSelect}
        inputProps={{
          name: 'selectedBankTransfer',
          id: 'bank-transfer-select-step-2',
        }}
        variant="outlined"
      >
        {listBank.map((ele, index) => {
          const { id, name } = ele;
          return (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={id}
              key={index}
            >
              <ListItemText primary={name} />
            </MenuItem>
          );
        })}
      </Select>
      <small className={classes.errorMessage}>
        <i>{messageError}</i>
      </small>
      <Typography variant="body2" className={classes.labelTextStep}>
          2. {t('paymentConfirm')}
      </Typography>
      {bankInfo && (
        <>
          <GridContainer className={classes.formItem}>
            <GridItem xs={12} sm={12} md={4} className={classes.formItemLabel}>
              <FormLabel className={classes.labelLeftHorizontal}>
                {t('bank')}:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} className={classes.formItemValue}>
              <Typography variant="h6" component="span">
                {bankInfo.bankName}
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.formItem}>
            <GridItem xs={12} sm={12} md={4} className={classes.formItemLabel}>
              <FormLabel className={classes.labelLeftHorizontal}>
                {t('accountNumber')}:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} className={classes.formItemValue}>
              <Typography variant="h6" component="span">
                {bankInfo.bankNumber}
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.formItem}>
            <GridItem xs={12} sm={12} md={4} className={classes.formItemLabel}>
              <FormLabel className={classes.labelLeftHorizontal}>
                {t('branch')}:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} className={classes.formItemValue}>
              <Typography variant="h6" component="span">
                {bankInfo.bankAddress}
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.formItem}>
            <GridItem xs={12} sm={12} md={4} className={classes.formItemLabel}>
              <FormLabel className={classes.labelLeftHorizontal}>
                {t('transferContent')}:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={12} md={8} className={classes.formItemValue}>
              <Typography variant="h6" component="span">
                {bankInfo.code}
              </Typography>
            </GridItem>
          </GridContainer>
        </>
      )}
    </GridItem>
  );
};

export default PaymentTransfer;
