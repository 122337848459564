import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';

import { Button, TablePagination } from '@material-ui/core';

import apis from '../../../apis';
import useStyles from './index.style.js';
import { formatNumber } from '../../../utils/number';
import { AGENT_STATUS } from '../../../constants';

import Table from 'components/Table/Table';
import TablePaginationActions from 'components/Table/TablePaginationActions';

const BowserAgentForm = ({ isActive, status, getAlert }) => {
  const classes = useStyles();
  const { t } = useTranslation('bowserAgent');

  const [alert, setAlert] = useState(null);
  const [listData, setListData] = useState(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [actionStatus, setActionStatus] = useState();

  const handleGetListData = async (params) => {
    try {
      const data = await apis.agent.getListAgents({
        page,
        limit: rowsPerPage,
        ...params,
      });
      if (data.error === 0) {
        setTotal(data.total);
        setListData(data.list);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (!isActive || listData) return;
    handleGetListData({ status: status, page: page, limit: rowsPerPage });
  }, [isActive, listData]);

  useEffect(() => {
    if (getAlert) getAlert(alert);
  }, [alert]);

  const hideAlert = () => {
    setAlert(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    handleGetListData({ status: status, page: newPage, limit: rowsPerPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    handleGetListData({
      status: status,
      page: 0,
      limit: parseInt(event.target.value, 10),
    });
  };

  const handeConfirmOrder = async (item) => {
    setAlert(null);
    try {
      const data = await apis.agent.updateAgents({
        account_id: item.accountId,
        status: AGENT_STATUS.SUCCESS,
      });
      if (data.error === 0) {
        handleGetListData({
          status: status,
          page: 0,
          limit: rowsPerPage,
        });
      }
    } catch (_) { }
  };

  const handeConfirmCancel = async (item) => {
    setAlert(null);
    try {
      const data = await apis.agent.updateAgents({
        account_id: item.accountId,
        status: AGENT_STATUS.ERROR,
      });
      if (data.error === 0) {
        handleGetListData({ status: status, page: 0, limit: rowsPerPage });
      }
    } catch (_) { }
  };

  const handelAcceptOrder = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => handeConfirmOrder(item)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('accept')}
        cancelBtnText={t('close')}
      >
        <p>
          {t('confirmPayment')} <b>{item.id} </b>?
        </p>
      </SweetAlert>,
    );
    return;
  };

  const handelCancelOrder = (item) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => handeConfirmCancel(item)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('accept')}
        cancelBtnText={t('close')}
      >
        <p>
          {t('cancelPayment')} <b>{item.id}</b>
        </p>
      </SweetAlert>,
    );
    return;
  };

  const tableData = () => {
    if (listData) {
      return listData.map((item, index) => {
        return [
          index + 1,
          item.id,
          item.account ? item.account.username : '',
          item.account ? item.account.phone : '',
          item.account ? item.account.email : '',
          formatNumber(item.money),
          <div>
            {status === AGENT_STATUS.WAITING && (
              <>
                <Button
                  className={classes.btnAccept}
                  onClick={() => {
                    setActionStatus(AGENT_STATUS.WAITING);
                    handelAcceptOrder(item);
                  }}
                >
                  {t('confirmBowser')}
                </Button>
                <Button
                  className={classes.btnCancel}
                  onClick={() => {
                    setActionStatus(AGENT_STATUS.ERROR);
                    handelCancelOrder(item);
                  }}
                >
                  {t('cancel')}
                </Button>
              </>
            )}
            {status === AGENT_STATUS.SUCCESS && <p>{t('approved')}</p>}
            {status === AGENT_STATUS.ERROR && (
              <Button
                className={classes.btnAccept}
                onClick={() => {
                  setActionStatus(AGENT_STATUS.WAITING);
                  handelAcceptOrder(item);
                }}
              >
                Duyệt lại
              </Button>
            )}
          </div>,
        ];
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    tableData();
  }, [listData, actionStatus]);

  return (
    <div className={classes.background}>
      <Table
        tableHead={[
          'STT',
          'ID',
          t('name'),
          t('phone'),
          'Email',
          t('money'),
          t('browsingStatus'),
        ]}
        tableData={tableData()}
        customCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
        customHeadCellClasses={[
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
      />
      <TablePagination
        className={classes.tablePagination}
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
};

export default BowserAgentForm;
