import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Datetime from 'react-datetime';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './index.style';
import classNames from 'classnames';

import CustomButton from 'components/CustomButtons/Button';
import CustomOutlinedInput from 'components/CustomInput/CustomOutlinedInput';

const PromotionForm = ({
  visible,
  onCloseDialog,
  title,
  promotionDetail,
  onConfirm,
}) => {
  const { t } = useTranslation('managerPromotion');
  const classes = useStyles();
  const { packages } = useSelector((state) => state.payment);
  const defaultValue = {
    packageId: '',
    sale: '',
    endDate: '',
    startDate: '',
    active: 1,
  };
  const [formValues, setFormValues] = useState(
    promotionDetail ? promotionDetail : defaultValue,
  );
  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'active') {
      if (checked === true) {
        setFormValues({
          ...formValues,
          active: 1,
        });
        return;
      }
      setFormValues({
        ...formValues,
        active: 0,
      });
      return;
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleDateTimeChange = (value, valueName) => {
    console.log({ valueName });
    const name = valueName;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <Dialog
      className={classes.dialogPromotion}
      classes={{
        root: classes.center + ' ' + classes.modalRoot,
        paper: classes.modal,
      }}
      open={Boolean(visible)}
      keepMounted
      onClose={onCloseDialog}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <h3
          className={classNames({
            [classes.modalTitle]: true,
            [classes.left]: true,
          })}
        >
          {title}
        </h3>
        <CustomButton
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onCloseDialog}
        >
          <CloseIcon className={classes.modalClose} />
        </CustomButton>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={`${classes.modalBody} ${classes.removePadding}`}
      >
        <form>
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            {t('package')}
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            fullWidth
            value={formValues.packageId}
            inputProps={{
              name: 'packageId',
              id: 'multiple-select',
            }}
            name="packageId"
            onChange={handleInputChange}
          >
            {packages.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={item.id}
                >
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            {t('value')}
          </InputLabel>
          <CustomOutlinedInput
            labelText={t('value')}
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              className: classes.input,
              placeholder: t('value'),
              value: formValues.sale,
              name: 'sale',
              onChange: handleInputChange,
            }}
          />
          <InputLabel className={classes.selectLabel}>
            {t('startDate')}
          </InputLabel>
          <Datetime
            inputProps={{
              placeholder: t('startDate'),
            }}
            dateFormat={'DD-MM-YYYY'}
            timeFormat={'HH:MM'}
            value={
              formValues.startDate
                ? moment(formValues.startDate).format('DD-MM-YYYY HH:MM')
                : ''
            }
            name="startDate"
            onChange={(value) => handleDateTimeChange(value, 'startDate')}
          />
          <InputLabel className={classes.selectLabel}>
            {t('endDate')}
          </InputLabel>
          <Datetime
            inputProps={{ placeholder: t('endDate') }}
            dateFormat={'DD-MM-YYYY'}
            value={
              formValues.endDate
                ? moment(formValues.endDate).format('DD-MM-YYYY HH:MM')
                : ''
            }
            name="endDate"
            onChange={(value) => handleDateTimeChange(value, 'endDate')}
          />
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            {t('status')}
          </InputLabel>
          <Switch
            checked={formValues.active}
            name="active"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={handleInputChange}
            color="primary"
            classes={{
              track: classes.switch_track,
              switchBase: classes.switch_base,
              colorPrimary: classes.switch_primary,
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.btnCancel}
          onClick={() => {
            onCloseDialog();
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          className={classes.btnAccept}
          onClick={() => {
            onCloseDialog();
            onConfirm(formValues);
          }}
        >
          {t('accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromotionForm;
