/*eslint-disable*/
import React, { useEffect } from 'react';

import defaultImage from 'assets/img/default-avatar.png';

// mterial-ui components
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import apis from '../../apis';
import { API_DOMAIN } from '../../configs';
import actions from '../../redux/actions';
import { IMAGES } from '../../constants';

const styles = {
  pictureSrc: {
    width: '100%',
    height: '100%',
    objectFit: "cover"
  },
  imgLoading: {
    width: '50%',
    textAlign: 'center',
    margin: 'auto',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  }
};

const useStyles = makeStyles(styles);

export default function PictureUpload() {
  const { userInfo } = useSelector((state) => state.auth);
  const { avatar } = userInfo || {};
  const classes = useStyles();
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(avatar);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const changeAvartar = async (image) => {
    if (image) {
      setLoading(true);
      try {
        const res = await apis.auth.updateAccount({ avatar: image });
        const { error } = res;
        if (error === 0) {
          dispatch(actions.auth.getUserInfo());
        }
      } catch (_) { }
      setLoading(false);
    }
  };

  const handleImageChange = async (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    reader.onloadend = async () => {
      setFile(newFile);
      // setImagePreviewUrl(reader.result);
    };
    setLoading(true);
    if (newFile) {
      reader.readAsDataURL(newFile);
      const formData = new FormData();
      formData.append('image', newFile);
      try {
        const res = await apis.resource.uploadFile({
          formData,
        });
        const { path } = res || {};
        if (path) {
          setImagePreviewUrl(path);
          changeAvartar(path);
        }
      } catch (_) { }
    }
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  };

  useEffect(() => {
    if (avatar) setImagePreviewUrl(avatar);
  }, [avatar]);

  return (
    <div className="picture-container">
      <div className="picture">
        {loading && <img src={IMAGES.iconLoading} className={classes.imgLoading} />}
        {!loading && <img src={imagePreviewUrl ? `${API_DOMAIN}${imagePreviewUrl}` : defaultImage} className={classes.pictureSrc} alt="..." />}
        <input type="file" onChange={(e) => handleImageChange(e)} />
      </div>
      <h6 className="description">Choose Picture</h6>
    </div>
  );
}
