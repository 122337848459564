import { all, put, takeLatest } from '@redux-saga/core/effects';
import apis from '../../apis';
import actions from '../actions';

function* getListPackagesSaga() {
  try {
    const res = yield apis.payment.getListPackages();
    const { error, list: packages, errorMsg } = res;
    if (error === 0) {
      yield put(actions.payment.getListPackagesSuccess(packages));
    } else {
      yield put(actions.payment.getListPackagesFailure(errorMsg));
    }
  } catch (error) {
    yield put(actions.payment.getListPackagesFailure(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.payment.actionTypes.GET_LIST_PACKAGES,
      getListPackagesSaga,
    ),
  ]);
}
