import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePagination } from '@material-ui/core';

import Table from 'components/Table/Table';
import TablePaginationActions from 'components/Table/TablePaginationActions';

import useStyles from './index.style';
import apis from 'apis';

const ListAccountPurchased = ({ isActive }) => {
  const { t } = useTranslation('cmsAgent');
  const classes = useStyles();

  const [listData, setListData] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getListData = async (params) => {
    try {
      const data = await apis.agent.getListAccount({
        ...params,
      });
      const { error, list, total } = data;
      if (error === 0) {
        setListData(list);
        setTotal(total);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (!isActive || listData) return;
    getListData({ page: page, limit: rowsPerPage });
  }, [isActive, listData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    getListData({ page: newPage, limit: rowsPerPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);

    getListData({
      page: 1,
      limit: parseInt(event.target.value, 10),
    });
  };

  const tableData = () => {
    if (listData) {
      return listData.map((item, index) => {
        return [
          index + 1,
          item.account.id,
          item.account.username,
          item.account.phone,
          item.account.email,
        ];
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    tableData();
  }, [listData]);

  return (
    <div className={classes.background}>
      <Table
        tableHead={[
          {
            minWidth: '50',
            title: 'STT',
          },
          {
            minWidth: '50',
            title: 'ID',
          },
          {
            minWidth: '150',
            title: t('fullName'),
          },
          {
            minWidth: '150',
            title: t('phoneNumber'),
          },
          {
            minWidth: '200',
            title: 'Email',
          },
        ]}
        tableData={tableData()}
        customCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4]}
        customHeadCellClasses={[
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
          `${classes.center} ${classes.bold}`,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4]}
      />
      <TablePagination
        className={classes.tablePagination}
        labelDisplayedRows={({ from, to, count }) =>
          `${from - rowsPerPage}- ${to > rowsPerPage ? to - rowsPerPage : to
          } of ${count}`
        }
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
};

export default ListAccountPurchased;
