export const actionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_WITH_FACEBOOK: 'LOGIN_WITH_FACEBOOK',
  LOGIN_WITH_FACEBOOK_SUCCESS: 'LOGIN_WITH_FACEBOOK_SUCCESS',
  LOGIN_WITH_FACEBOOK_FAILURE: 'LOGIN_WITH_FACEBOOK_FAILURE',
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_FAILURE: 'VERIFY_TOKEN_FAILURE',
  LOGOUT: 'LOGOUT',
  GET_USER_INFO: 'GET_USER_INFO',
  GET_USER_INFO_SUCESS: 'GET_USER_INFO_SUCESS',
  GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
  UPDATE_TOTAL_VIDEO: 'UPDATE_TOTAL_VIDEO',
  UPDATE_MEMBER_FIREBASE_TOKEN: 'UPDATE_MEMBER_FIREBASE_TOKEN',
  UPDATE_MEMBER_FIREBASE_TOKEN_SUCCESS: 'UPDATE_MEMBER_FIREBASE_TOKEN_SUCCESS',
  UPDATE_MEMBER_FIREBASE_TOKEN_FAILURE: 'UPDATE_MEMBER_FIREBASE_TOKEN_FAILURE',
};

export function login(email, password) {
  return {
    type: actionTypes.LOGIN,
    email,
    password,
  };
}

export function loginSuccess(accessToken) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    accessToken,
  };
}

export function loginFailure() {
  return {
    type: actionTypes.LOGIN_FAILURE,
  };
}

export function loginWithFacebook(token) {
  return {
    type: actionTypes.LOGIN_WITH_FACEBOOK,
    payload: token,
  };
}

export function loginWithFacebookSuccess(data) {
  return {
    type: actionTypes.LOGIN_WITH_FACEBOOK_SUCCESS,
    payload: data,
  };
}

export function loginWithFacebookFailure(message) {
  return {
    type: actionTypes.LOGIN_WITH_FACEBOOK_FAILURE,
    payload: message,
  };
}

export function verifyToken(accessToken) {
  return {
    type: actionTypes.VERIFY_TOKEN,
    accessToken,
  };
}

export function verifyTokenSuccess(accessToken, userInfo) {
  return {
    type: actionTypes.VERIFY_TOKEN_SUCCESS,
    accessToken,
    userInfo,
  };
}

export function verifyTokenFailure() {
  return {
    type: actionTypes.VERIFY_TOKEN_FAILURE,
  };
}

export function logout() {
  return {
    type: actionTypes.LOGOUT,
  };
}

export function getUserInfo() {
  return {
    type: actionTypes.GET_USER_INFO,
  };
}

export function getUserInfoSuccess(userInfo) {
  return {
    type: actionTypes.GET_USER_INFO_SUCESS,
    userInfo,
  };
}

export function getUserInfoFailure() {
  return {
    type: actionTypes.GET_USER_INFO_FAILURE,
  };
}

export function updateTotalVideo(totalVideoCurrentUse) {
  return {
    type: actionTypes.UPDATE_TOTAL_VIDEO,
    totalVideoCurrentUse,
  };
}

export const updateMemberFireBaseToken = (userId, firebaseToken) => {
  return {
    type: actionTypes.UPDATE_MEMBER_FIREBASE_TOKEN,
    userId,
    firebaseToken,
  };
};

export const updateMemberFireBaseTokenSuccess = (firebaseToken) => {
  return {
    type: actionTypes.UPDATE_MEMBER_FIREBASE_TOKEN_SUCCESS,
    firebaseToken,
  };
};

export const updateMemberFireBaseTokenFailure = () => {
  return {
    type: actionTypes.UPDATE_MEMBER_FIREBASE_TOKEN_FAILURE,
  };
};
