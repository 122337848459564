import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    marginBottom: '43px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '24px',
    },
  },
  info: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'bold',
    letterSpacing: '-0.3px',
    color: '#2574BA',
    textTransform: 'uppercase',
    margin: '0px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '15px',
    },
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '36px',
    lineHeight: '44px',
    fontWeight: 600,
    color: '#232323',
    letterSpacing: '-0.3px',
    textTransform: 'uppercase',
    marginTop: '4px',
    marginBottom: '8px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
      lineHeight: '32px',
    },
  },
  underline: {
    position: 'relative',
    height: '7px',
    maxWidth: '333px',
    width: '80%',
    background: 'linear-gradient(90deg, #2CA8E0 0%, #2E4396 100%)',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 0,
      border: '7px solid transparent',
      borderTopColor: 'white',
      borderRightColor: 'white',
    },
  },
}));
