import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import { IMAGES, ROUTES } from '../../../../constants';
import styles from './index.style';
import { API_DOMAIN, PUBLIC_URL } from 'configs';
import ReportError from '../../../../containers/ReportError';

const useStyles = makeStyles(styles);

const Sidebar = ({
  currentTemplate,
  handleUpdateTemplate,
  handleCreateVideo,
  exporting,
  acceptPolicy,
  setAcceptPolicy,
  loadingCreated,
  imageUpload,
}) => {
  const classes = useStyles();

  const { t } = useTranslation('createTemplate');

  const { thumb, listScene, selectedScene } = currentTemplate || {
    listScene: [
      {
        images: [],
        rate: 1,
        selectedLang: 'vn',
        selectedVoice: 0,
        text: '',
      },
    ],
    selectedScene: 0,
  };

  const imageThumb = useMemo(() => {
    if (imageUpload) return imageUpload;
    if (!currentTemplate && !imageUpload) return IMAGES.bannerHome;
    return `${API_DOMAIN}${thumb}`;
  }, [currentTemplate, imageUpload]);

  const listScenes = useMemo(() => {
    // if (imageUpload) return listScene;
    if (listScene) return listScene;
  }, [currentTemplate, imageUpload, listScene]);

  const handleAddScene = () => {
    if (currentTemplate === null) return;
    const newListScene = [
      ...listScene,
      {
        text: '',
        selectedLang: 'vn',
        selectedVoice: 0,
        rate: 1.0,
        images: [],
      },
    ];

    let newTemplate = {};
    if (!currentTemplate) {
      newTemplate = {
        ...currentTemplate,
        listScene: newListScene,
        selectedScene: 0,
      };
    } else {
      newTemplate = {
        ...currentTemplate,
        listScene: newListScene,
      };
    }
    handleUpdateTemplate(newTemplate);
  };

  const handleDeleteScene = (index) => {
    if (listScene.length < 2) return;
    const newListScene = listScene.filter((item, idx) => idx !== index);

    let { selectedScene } = currentTemplate;
    if (index < selectedScene) {
      selectedScene -= 1;
    } else if (index === selectedScene) {
      selectedScene = null;
    }

    const newTemplate = {
      ...currentTemplate,
      listScene: newListScene,
      selectedScene: selectedScene,
    };
    handleUpdateTemplate(newTemplate);
  };

  const handleSelectScene = (index) => {
    // if (!currentTemplate) return;
    const newTemplate = {
      ...currentTemplate,
      selectedScene: index,
    };
    handleUpdateTemplate(newTemplate);
  };

  const renderSceneList = () => {
    return listScenes.map((item, index) => (
      <GridItem xs={12} sm={12} md={12} key={index}>
        <div
          className={classes.sceneContainer}
          onClick={() => handleSelectScene(index)}
        >
          <div className={classes.sceneContent}>
            <p className={classes.sceneOrder}>{index + 1}.</p>
            <div
              className={cx({
                [classes.sceneImage]: true,
                [classes.sceneImageActive]: index === selectedScene,
              })}
            >
              <img src={imageThumb} alt="..." />
            </div>
            <Button
              className={classes.delButton}
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteScene(index);
              }}
            >
              <img src={IMAGES.deleteVideoIcon} alt="del-icon" />
            </Button>
          </div>
        </div>
      </GridItem>
    ));
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainScene}>
        <GridContainer className={classes.sceneList}>
          {renderSceneList()}
        </GridContainer>
        <div>
          <Divider style={{ marginTop: '10px' }} />
          <ButtonBase
            variant="contained"
            className={cx(classes.btnBase, classes.btnAddScene)}
            onClick={handleAddScene}
          >
            <img src={IMAGES.addSceneIcon} alt="" />
            <span>{t('addScene')}</span>
          </ButtonBase>
          <FormControlLabel
            classes={{
              root: classes.checkboxLabelControl,
              label: classes.checkboxLabel,
            }}
            control={
              <Checkbox
                tabIndex={-1}
                checkedIcon={<CheckIcon className={classes.checkedIcon} />}
                icon={<CheckIcon className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                value={acceptPolicy}
                onChange={(event, value) => {
                  setAcceptPolicy(value);
                }}
              />
            }
            label={
              <span className={classes.termsAndCondition}>
                {t('agreeWith')}{' '}
                <Link
                  to={`${PUBLIC_URL}/DIEU_KHOAN_SU_DUNG_AICLIP.pdf`}
                  target="_blank"
                >
                  {t('policyAndTerms')}
                </Link>{' '}
                {t('ofAICLIP')}
              </span>
            }
          />
          <div className={classes.boxCreateVideo}>
            <ButtonBase
              variant="contained"
              className={cx(classes.btnBase, classes.btnCreateVideo)}
              onClick={handleCreateVideo}
              disabled={exporting}
            >
              <img src={IMAGES.shareIcon} alt="" />
              <span>{exporting ? t('processing') : t('exportVideo')}</span>
            </ButtonBase>
            {loadingCreated && (
              <div className={classes.imgLoading}>
                <img src={`${IMAGES.iconLoading}`} alt="" />
              </div>
            )}
          </div>
          <ReportError />
        </div>
      </div>
    </div>
  );
};
export default Sidebar;

Sidebar.propTypes = {
  currentTemplate: PropTypes.object,
  handleUpdateTemplate: PropTypes.func,
  handleCreateVideo: PropTypes.func,
  exporting: PropTypes.bool,
  acceptPolicy: PropTypes.bool,
  setAcceptPolicy: PropTypes.func,
};
