import api from './api';

export async function getListVoice(params) {
  const data = await api({
    method: 'GET',
    url: '/home/voices',
    params,
  });
  return data;
}

export async function convertTTS({ inputText, voice, rate }) {
  const data = await api({
    method: 'GET',
    url: '/home/tts',
    params: {
      input_text: inputText,
      voice,
      rate,
    },
  });
  return data;
}
