import { makeStyles } from '@material-ui/core';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

export default makeStyles((theme) => ({
  ...buttonStyle,
  background: {
    padding: '10px',
    background: '#fff',
    '& .MuiTableHead-root': {
      background: '#eaeced',
    },
  },
  switch_track: {
    backgroundColor: '#6a6868',
  },
  switch_base: {
    color: '#999',
    '&.Mui-disabled': {
      color: '#e886a9',
    },
    '&.Mui-checked': {
      color: '#95cc97',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4CAF50',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#4CAF50',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4CAF50',
    },
  },
  boxCreate: {
    textAlign: 'right',
  },
  btnCreate: {
    background: '#2e4799',
    color: '#fff',
    border: '1px solid #2e4799',
    padding: '5px 20px',
    '&:hover': {
      background: '#fff',
      color: '#2e4799',
    },
    '& .MuiButton-label': {
      paddingTop: '3px',
    },
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  center: {
    border: '1px solid #DDD !important',
    textAlign: 'center',
  },
  bold: {
    fontWeight: '400',
  },
  tablePagination: {
    width: '100%',
  },
}));
