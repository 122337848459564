import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';

import ImageEditorTui from 'tui-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

import useStyles from './index.style';

import apis from 'apis';
import { API_DOMAIN } from 'configs';

export default function ImageEditor({
  imageTemplate,
  getIndexTemplate,
  getIndexMC,
  getImageUpload,
  imageMC,
  getDataSend,
}) {
  const classes = useStyles();
  const editorRef = useRef();
  const { t } = useTranslation('createTemplate');

  const [imageEditor, setImageTui] = useState();
  let isDowload = false;
  const dataTuiImage = useRef([]);
  const imageLoad = useRef();
  const imageUpload = useRef();
  const oldMC = useRef();
  const oldTemplate = useRef();
  const indexImageMC = useRef();
  const TUI_selectedItem = useRef();
  let image = '';

  const config = {
    includeUI: {
      loadImage: {
        path: imageLoad.current
          ? imageLoad.current
          : `${API_DOMAIN}${imageTemplate}`,
        name: 'SampleImage',
      },
      menu: [
        'resize',
        'crop',
        'flip',
        'rotate',
        'draw',
        'shape',
        'icon',
        'text',
        'mask',
        'filter',
      ],
      initMenu: '',
      uiSize: {
        width: '100%',
        height: '70vh',
      },
      menuBarPosition: 'bottom',
    },
    cssMaxWidth: document.documentElement.clientWidth / 1.9,
    cssMaxHeight: document.documentElement.clientHeight / 1.8,
    selectionStyle: {
      cornerSize: 20,
      rotatingPointOffset: 70,
    },
  };

  useEffect(() => {
    if (imageTemplate) {
      imageLoad.current = '';
      getIndexMC('');
    }
    const editor = new ImageEditorTui(
      document.querySelector('#tui-image-editor'),
      {
        ...config,
      },
    );
    setImageTui(editor);

    let fontArray = [
      'Arial',
      'Arial Black',
      'Caveat',
      'Comic Sans MS',
      'Courier New',
      'Georgia1',
      'Impact',
      'Lobster Two',
      'Lucida Console',
      'Luckiest Guy',
      'Open Sans',
      'Pacifico',
      'Palatino Linotype',
      'Press Start 2P',
      'Roboto',
      'Tahoma',
      'Tangerine',
      'Times New Roman',
      'Tourney',
      'Ultra',
      'Verdana',
      'Symbol',
      'Webdings',
      'Wingdings',
    ];

    let fontSelectHTML =
      '<select #fontselect class="form-select font-selector">';
    for (let i = 0; i < fontArray.length; i++) {
      let selected = '';
      if (i == 0) {
        selected = 'selected';
      }
      fontSelectHTML +=
        '<option style="font-family:' +
        fontArray[i] +
        ';" value="' +
        fontArray[i] +
        '" ' +
        selected +
        '>' +
        fontArray[i] +
        '</option>';
    }
    fontSelectHTML += '</select>';

    let textMenuAlign = document.querySelector(
      '.tui-image-editor-menu-text .tie-text-align-button',
    );
    textMenuAlign.insertAdjacentHTML('afterbegin', fontSelectHTML);
  }, [imageTemplate, imageLoad.current]);

  const TUI_updateFontOnText = (font) => {
    if (font && TUI_selectedItem) {
      imageEditor.changeTextStyle(TUI_selectedItem.current, {
        fontFamily: font,
      });
    }
  };

  useEffect(() => {
    if (imageEditor) {
      document
        .getElementsByClassName('tui-image-editor-load-btn')[1]
        .addEventListener('change', function (e) {
          isDowload = true;
          uploadFile(e);
        });
      if (imageTemplate) {
        if (oldTemplate.current !== imageTemplate) {
          oldTemplate.current = imageTemplate;
          dataTuiImage.current = [];
        }
      }
    }
  }, [imageTemplate, imageEditor, imageLoad.current]);

  useEffect(() => {
    if (imageEditor) {
      document
        .querySelector('.font-selector')
        .addEventListener('change', () =>
          TUI_updateFontOnText(document.querySelector('.font-selector').value),
        );
      TUI_updateFontOnText(document.querySelector('.font-selector').value);
      imageEditor.on('objectActivated', function (props) {
        const newData = dataTuiImage.current;
        const index = newData.findIndex((ele) => ele.id === props.id);
        let newProps = {};
        if (props.type === 'image') {
          newProps = { ...props, image: imageUpload.current };
        } else {
          newProps = { ...props };
        }
        if (index != -1) {
          newData[index] = newProps;
        } else {
          newData.push(newProps);
        }
        dataTuiImage.current = newData;
        document.addEventListener('keydown', function (event) {
          if (event.keyCode === 46) {
            if (index > -1) {
              dataTuiImage.current.splice(index, 1);
            }
          }
        });
        TUI_selectedItem.current = props.id;
        TUI_updateFontSelected(props);
        imageEditor.changeTextStyle(props.id, {
          fontFamily: document.querySelector('.font-selector').value,
        });
      });
    }
  }, [imageEditor]);

  const TUI_updateFontSelected = (layer) => {
    if (layer.fontFamily) {
      document.querySelector('.font-selector').value = layer.fontFamily;
    }
  };

  const uploadFile = async (e) => {
    try {
      const files = e.target.files;
      const formData = new FormData();
      formData.append('image', files[0]);

      const res = await apis.resource.uploadFile({
        formData,
      });

      if (isDowload) {
        imageLoad.current = res.public;
        getIndexTemplate(-1);
        getIndexMC(-1);
        getImageUpload(res.public);
        indexImageMC.current = -1;
        oldMC.current = '';
        dataTuiImage.current = [];
        return;
      }
      image = res.path;
    } catch (_) { }
  };
  useEffect(() => {
    if (imageEditor) {
      try {
        const newData = dataTuiImage.current;
        // imageEditor.addImageObject(imageMC);
        if (imageMC || indexImageMC.current === -1) {
          imageEditor.addImageObject(imageMC);
          if (oldMC !== 'undefined' && oldMC.current !== imageMC) {
            if (indexImageMC.current === -1) {
              newData.splice(0, newData.length);
              indexImageMC.current = 0;
            }
            const index = newData.findIndex(
              (ele) => ele.image === oldMC.current,
            );
            if (index !== -1) {
              setTimeout(() => {
                imageEditor.removeObject(newData[index].id);
                dataTuiImage.current.splice(index, 1);
              }, 500);
            }
          }

          imageUpload.current = imageMC;
          oldMC.current = imageMC;
        }
      } catch (_) { }
    }
  }, [imageMC]);

  const onSaveImage = () => {
    try {
      var canvasSize = imageEditor.getCanvasSize();
      const dataLoad = {
        id: 1,
        type: 'image-background',
        width: canvasSize.width,
        height: canvasSize.height,
        image: imageLoad.current ? imageLoad.current : imageTemplate,
      };
      const dataNew = dataTuiImage.current.map((item) => {
        var valueText = '';
        var valueImage = '';
        var valueWidthHeight = imageEditor.getObjectProperties(item.id, [
          'width',
          'height',
          'left',
          'top',
        ]);
        if (item.type === 'i-text') {
          valueText = imageEditor.getObjectProperties(item.id, [
            'text',
            'fill',
            'fontSize',
            'fontWeight',
            'textAlign',
            'fontStyle',
            'fontFamily',
          ]);
        } else if (item.type === 'image') {
          valueImage = imageUpload.current;
        }

        const dataFormat = {
          id: item.id,
          text: valueText ? valueText.text : '',
          left: valueWidthHeight.left,
          top: valueWidthHeight.top,
          image: valueImage ? valueImage : '',
          type: item.type,
          width: valueWidthHeight.width,
          height: valueWidthHeight.height,
          fontSize: valueText.fontSize,
          color: valueText.fill,
          fontWeight: valueText.fontWeight,
          fontStyle: valueText.fontStyle,
          textAlign: valueText.textAlign,
          fontFamily: valueText.fontFamily,
        };
        return dataFormat;
      });
      imageEditor.discardSelection();
      const dataSend = [dataLoad, ...dataNew];
      console.log({ dataSend });
      getDataSend(dataSend);
      return;
    } catch (e) {
      console.log(e);
    }
  };

  const onUpImage = async (e) => {
    try {
      const files = e.target.files;
      const formData = new FormData();
      formData.append('image', files[0]);
      const res = await apis.resource.uploadFile({
        formData,
      });
      imageUpload.current = res.public;
      imageEditor.addImageObject(res.public);
    } catch (_) { }
  };

  return (
    <>
      <div id="tui-image-editor" ref={editorRef} className={classes.bg}></div>
      <div className={classes.customBtn}>
        <Button onClick={() => onSaveImage()} className={classes.btnAccept}>
          {t('saveImage')}
        </Button>
        <Button className={classes.btnUpload}>
          {t('uploadImage')}
          <input
            className={classes.customInputFile}
            type="file"
            accept="image/*"
            id="input-image-file"
            onChange={(e) => onUpImage(e)}
          />
        </Button>
      </div>
      <p className={classes.noteText}>{t('noteImage')}</p>
    </>
  );
}
