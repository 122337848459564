import { actionTypes } from './actions';

export const initialState = {
  packages: [],
  fetchingPackage: false,
  message: null,
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LIST_PACKAGES:
      return { ...state, fetchingPackage: true };

    case actionTypes.GET_LIST_PACKAGES_SUCCESS: {
      const { payload } = action;
      return { ...state, fetchingPackage: false, packages: payload };
    }

    case actionTypes.GET_LIST_PACKAGES_FAILURE: {
      const { payload } = action;
      return { ...state, fetchingPackage: false, message: payload };
    }

    default:
      return state;
  }
}
