import api from './api';

export async function getListPackages(params) {
  const data = await api({
    method: 'GET',
    url: '/payment/list',
    params,
  });
  return data;
}

export async function getListBank() {
  const data = await api({
    method: 'GET',
    url: '/payment/atm_bank',
  });
  return data;
}

export async function getListBankTransfer() {
  const data = await api({
    method: 'GET',
    url: '/payment/bank_tranfer',
  });
  return data;
}

export async function createPayment(data) {
  const result = await api({
    method: 'POST',
    url: '/payment/create',
    data,
  });
  return result;
}

export async function getListOrders(params) {
  const result = await api({
    method: 'GET',
    url: '/payment/orders',
    params,
  });
  return result;
}

export async function createPaymentWithCms(data) {
  const result = await api({
    method: 'POST',
    url: '/payment/create_with_cms',
    data,
  });
  return result;
}
