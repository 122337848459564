import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './languages';
import App from './containers/App';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'assets/scss/material-dashboard-pro-react.scss?v=1.10.0';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import { initializeFirebase } from './push-notification';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<CircularProgress />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

initializeFirebase();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
